import React from 'react';

import { RunDetailsTab } from '@neptune/project-runs-domain';

import { isValidRunDetailsTab } from './isValidRunDetailsTab';

export const tabs: RunDetailsTab[] = [];
export const components: Record<string, React.FC> = {};

export function registerRunDetailsContentView(
  label: string | null,
  code: string,
  Component?: React.FC,
  description?: string,
) {
  if (!isValidRunDetailsTab(code)) {
    throw new Error('This tab code is invalid. Did you forget to update the list?');
  }

  if (label !== null) {
    tabs.push({
      type: 'experiment',
      dashboardId: code,
      routeParams: Component ? { detailsTab: code } : { detailsTab: '', tab: code },
      label,
      description,
      enabledInArchive: true,
    });
  }

  if (Component) {
    components[code] = Component;
  }
}
