import { LeaderboardView } from '@neptune/shared/lb-views-domain';
import {
  defaultVisibleColumnsSettings,
  RunsLineage,
  STANDARD_SORTING_OPTIONS,
} from '@neptune/shared/leaderboard-domain';
import { NameSearchMode } from '@neptune/shared/search-domain';

import config from 'config';

export const standardView: LeaderboardView = {
  id: 'standard-view',
  name: 'All runs',
  nameSearchQuery: '',
  nameSearchMode: NameSearchMode.REGEX,
  columnList: defaultVisibleColumnsSettings,
  defaultView: false,
  sortOptions: STANDARD_SORTING_OPTIONS,
  suggestionsEnabled: config.suggestionsEnabled,
  experimentsOnly: true,
  runsLineage: RunsLineage.FULL,
  isArchived: false,
  showSelectedHiddenByFilter: false,
};

export const archivedView: LeaderboardView = {
  id: 'archived-view',
  name: 'Archived runs',
  nameSearchQuery: '',
  nameSearchMode: NameSearchMode.REGEX,
  columnList: defaultVisibleColumnsSettings,
  defaultView: false,
  sortOptions: STANDARD_SORTING_OPTIONS,
  suggestionsEnabled: config.suggestionsEnabled,
  experimentsOnly: true,
  runsLineage: RunsLineage.FULL,
  isArchived: true,
  showSelectedHiddenByFilter: false,
};

export function getViewType(lbViewId: string) {
  if (lbViewId === standardView.id) {
    return 'standard';
  }

  if (lbViewId === archivedView.id) {
    return 'archived';
  }

  return 'custom';
}
