export type SelectionState = {
  leaderboardId?: string;
  entries: string[];
  expectedRequestId?: string;
};

export enum SelectionActionTypes {
  Select = 'UI_SELECTED_LEADERBOARD_ENTRIES_SELECT',
  Deselect = 'UI_SELECTED_LEADERBOARD_ENTRIES_DESELECT',
  DeselectAll = 'UI_SELECTED_LEADERBOARD_ENTRIES_DESELECT_ALL',
}
