import React from 'react';

import { UnderEditContext, underEditModel } from '@neptune/shared/common-domain';

export function UnderEditContextProvider({ children }: { children: React.ReactNode }) {
  const [underEdit, setUnderEdit] = React.useState(false);

  const model = React.useRef(underEditModel());

  React.useEffect(() => {
    const subscription = model.current.observable$.subscribe(setUnderEdit);

    return () => subscription.unsubscribe();
  }, []);

  const startEdit = React.useCallback(() => {
    model.current.startEdit();
  }, []);

  const stopEdit = React.useCallback(() => {
    model.current.stopEdit();
  }, []);

  const startEditWindow = React.useCallback((windowCloseDelay: number) => {
    model.current.startEditWindow(windowCloseDelay);
  }, []);

  const stopEditWindow = React.useCallback(() => {
    model.current.stopEditWindow();
  }, []);

  const contextValue = React.useMemo(
    () => ({
      underEdit,
      underEdit$: model.current.observable$,
      startEdit,
      stopEdit,
      startEditWindow,
      stopEditWindow,
    }),
    [underEdit, startEdit, stopEdit, startEditWindow, stopEditWindow],
  );

  return <UnderEditContext.Provider value={contextValue}>{children}</UnderEditContext.Provider>;
}
