import { AppState } from 'state/types';

import getParentState from '../selectors';

import { SelectionState } from './types';

export const getSelectionState = (state: AppState): SelectionState =>
  getParentState(state).selection;

export const getSelectedLeaderboardEntriesIds = (state: AppState) =>
  getSelectionState(state).entries;

export default getSelectionState;
