import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

export async function archiveEntities(entityUuidIdentifiers: string[]) {
  return leaderboardClient.archiveRuns({
    runList: {
      runs: entityUuidIdentifiers,
    },
  });
}

export async function unarchiveEntities(entityUuidIdentifiers: string[]) {
  return leaderboardClient.unarchiveRuns({
    runList: {
      runs: entityUuidIdentifiers,
    },
  });
}
