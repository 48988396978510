import React from 'react';

import { SearchQueryContext } from '@neptune/object-table-search-domain';

export function useSearchQuery() {
  const value = React.useContext(SearchQueryContext);

  if (!value) {
    throw new Error('useSearchQuery must be used within a SearchQueryContext');
  }

  return value;
}
