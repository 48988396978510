import { ColumnIdentifier, LeaderboardColumn } from './column/column-model';

export type BaseLeaderboardColumn = ColumnIdentifier & {
  color?: string;
  displayName?: string;
  pinned: boolean;
  width: number;
  autoWidthEnabled?: boolean;
};

export type EnhancedLeaderboardColumn = BaseLeaderboardColumn & {
  isSuggestion?: boolean;
  title: string;
};

export type SuggestedLeaderboardColumn = LeaderboardColumn & {
  isSuggestion: boolean;
  isLoading?: boolean;
  title: string;
};

export const MAGIC_AUTO_COLUMN_WIDTH = 151; // This value is a magic number and needs to be different from the previous default value of 150

export function shouldBeAutoWidth(column: BaseLeaderboardColumn) {
  return !!column.autoWidthEnabled && column.width === MAGIC_AUTO_COLUMN_WIDTH;
}
