import { useCompareContext } from './use-compare-context';

export function useCompareIdsForPolling() {
  const {
    hiddenCount,
    selectedCount,
    runIds: compareIds,
    showOnlySelectedRuns,
    runIdsReady,
  } = useCompareContext();

  return {
    compareIds,
    enabled: !(showOnlySelectedRuns && !runIdsReady),
    compareIdsForPolling:
      showOnlySelectedRuns && hiddenCount < selectedCount ? compareIds : undefined,
  };
}
