import { last } from 'lodash';

import { SearchQueryTerm, SearchQueryTermType } from '../types';

export const getNextAllowedOperators = (query: SearchQueryTerm[]) => {
  const lastTerm = last(query);

  if (!lastTerm) {
    return [];
  }

  if (
    lastTerm.type === SearchQueryTermType.OPERATOR &&
    lastTerm.value !== 'AND NOT' &&
    lastTerm.value !== 'OR'
  ) {
    return [{ type: SearchQueryTermType.OPERATOR, value: 'NOT' }];
  }

  if (lastTerm.type === SearchQueryTermType.OPERATOR) {
    return [];
  }

  return [
    { type: SearchQueryTermType.OPERATOR, value: 'AND' },
    { type: SearchQueryTermType.OPERATOR, value: 'OR' },
  ];
};
