import React from 'react';

import { RegExpInstructionsModal } from '@neptune/shared/regexp-instructions-modal-ui';
import { Icon, Layout, Link, SimpleTooltip, Text } from '@neptune/shared/venus-ui';

export const RegExpTooltip = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const content = (
    <Text>
      Supports regex.{' '}
      <Link
        onClick={(event) => {
          event.stopPropagation();
          open();
        }}
      >
        Help
      </Link>
    </Text>
  );

  return (
    <>
      <RegExpInstructionsModal onClose={close} isOpen={isOpen} />
      <SimpleTooltip content={content}>
        <Layout.Element withPadding="xs">
          <Icon color="info" glyph="regexp" />
        </Layout.Element>
      </SimpleTooltip>
    </>
  );
};
