// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { getLastNotificationTimestamp } from '@neptune/shared/leaderboard-business-logic';

// App
import { fetchLeaderboardCount } from 'state/project-leaderboard/lb-count/actions';

// Module
const selectLastNotificationTimestamp = (state: any) => getLastNotificationTimestamp(state, 'runs');

export const useLbCount = () => {
  const dispatch = useDispatch();
  const lastNotificationTimeStamp = useSelector(selectLastNotificationTimestamp);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);

  React.useEffect(() => {
    dispatch(
      fetchLeaderboardCount({
        projectIdentifier,
      }),
    );
  }, [dispatch, projectIdentifier, lastNotificationTimeStamp]);
};
