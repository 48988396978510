const predefinedPatterns = [
  [1, 0], // solid
  [2, 2], // short dash
  [1, 2, 1, 2], // dash dot
  [4, 4], // dash dash
  [3, 2, 2, 2], // long dash dot
  [6, 2], // long dash
];

import { getBinaryDashDotPattern } from './binary-based-stroke-patterns';

export function getStrokePatternByIndex(index: number) {
  return index < predefinedPatterns.length
    ? predefinedPatterns[index]
    : getBinaryDashDotPattern(index - predefinedPatterns.length);
}
