import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { Icon, KeyShortcut, Layout, Link, Modal, Text } from '@neptune/shared/venus-ui';

import { getTextualGrammarCompoundSearchEnabled } from 'common/featureFlag';

export const RegExpInstructionsModal = ({
  isOpen,
  onClose,
  supportExtendedRegex = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  supportExtendedRegex?: boolean;
}) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      data={{ role: 'regexp-instructions-modal' }}
      version={2}
    >
      <Modal.Header
        title={
          getTextualGrammarCompoundSearchEnabled()
            ? 'Extended regular expressions'
            : 'Regular expressions - quick reference'
        }
        onClose={onClose}
      />
      <Modal.Body>
        <Layout.Column spacedChildren="md">
          <Layout.Column spacedChildren="sm">
            <Example example=".">any one character</Example>
            <Example example="^">beginning of text</Example>
            <Example example="$">end of text</Example>
            <Example example="+">one or more occurrences of the preceding element</Example>
            <Example example="*">zero or more occurrences of the preceding element</Example>
            <Example example="{n}">exactly n occurrences of the preceding element</Example>
            <Example example="ab|cd">
              <Text fontWeight="bold">ab</Text> or <Text fontWeight="bold">cd</Text>
            </Example>
            <Example example="[abc]">
              any of the characters <Text fontWeight="bold">a</Text>,{' '}
              <Text fontWeight="bold">b</Text> or <Text fontWeight="bold">c</Text>
            </Example>
            <Example example="[^abc]">
              any one character except <Text fontWeight="bold">a</Text>,{' '}
              <Text fontWeight="bold">b</Text> or <Text fontWeight="bold">c</Text>
            </Example>
          </Layout.Column>
          {getTextualGrammarCompoundSearchEnabled() && supportExtendedRegex && (
            <>
              <Text> Extended syntax, for operating on regex patterns:</Text>
              <Layout.Column spacedChildren="sm">
                <Example example="&">AND</Example>
                <Example example="|">OR</Example>
                <Example example="& !">AND NOT</Example>
              </Layout.Column>
              <Layout.Column spacedChildren="xs">
                <Text>
                  <Text fontWeight="bold">Note:</Text> To be recognized as operators, the symbols
                  must be preceded by a space.
                  <br /> To match a literal space, type the string: \x20
                </Text>
              </Layout.Column>
            </>
          )}
          <Text color="text-subdued">
            {' '}
            For details and examples, see the{' '}
            <Link href={neptuneUrls.regex} external fontWeight="normal">
              documentation <Icon glyph="external-link" />
            </Link>
          </Text>
        </Layout.Column>
      </Modal.Body>
    </Modal>
  );
};

function Example({ example, children }: { example: string; children: React.ReactNode }) {
  return (
    <Layout.Row alignItems="start" spacedChildren="sm">
      <KeyShortcut theme="light">{example}</KeyShortcut>
      <Layout.Row spacedChildren="xs">
        <Text>&#8212;</Text>
        <Text>{children}</Text>
      </Layout.Row>
    </Layout.Row>
  );
}
