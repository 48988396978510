import React from 'react';
import { noop } from 'lodash';

type ChartRef = React.RefObject<any>;

export type BulkPngExportContextValue = {
  registerChartRefAsBulkExportCandidate: (widgetId: string, chartRef: ChartRef) => void;
  unregisterChartRefAsBulkExportCandidate: (widgetId: string) => void;
  getChartRef: (widgetId: string) => ChartRef | undefined;
  registeredCharts: Map<string, ChartRef>;
  downloadBulkSelectedPng: () => void;
  downloadBulkSelectedPngToClipboard: () => void;
  bulkPngExportCandidatesWidgetIds: string[];
  showMetricByColor?: boolean;
  exportDisabledState: {
    isDisabled: boolean;
    reason?: string;
  };
};

export const BulkPngExportContext = React.createContext<BulkPngExportContextValue>({
  registerChartRefAsBulkExportCandidate: noop,
  unregisterChartRefAsBulkExportCandidate: noop,
  getChartRef: () => undefined,
  registeredCharts: new Map(),
  downloadBulkSelectedPng: noop,
  downloadBulkSelectedPngToClipboard: noop,
  bulkPngExportCandidatesWidgetIds: [],
  exportDisabledState: { isDisabled: false },
});
