import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

import { EntityModelConverter } from './entity-model-converter';

export async function getEntityUuids(
  projectIdentifier: string,
  types: string[],
  query: string,
  limit: number,
) {
  const searchResult = await leaderboardClient.searchLeaderboardEntries({
    projectIdentifier,
    params: {
      query: { query },
      pagination: {
        limit,
      },
    },
    type: types,
  });

  const entities = searchResult.entries.map(EntityModelConverter.entityFromApiToDomain);

  return entities;
}
