import { getTitleFromMarkdown } from '@neptune/markdown-widget-business-logic';
import { DIFF_MARKER, findCommonPartsAndDiffs } from '@neptune/shared/common-util';
import {
  isAttributePatternSource,
  isAttributeSource,
  isYCustomExpressionSource,
  type Widget,
  type WidgetSource,
  WidgetSourceType,
} from '@neptune/shared/widgets-domain';

import { AttributeDefinition } from 'domain/experiment/attribute';

// @todo attribute-pattern we should handle multiple attribute patterns in the future
export function sourcesToAttributePattern(sources: WidgetSource[]): string | undefined {
  return sources.find(isAttributePatternSource)?.value;
}

function inferNameFromSources(sources: WidgetSource[]) {
  const freeTextSource = sources.find((source) => source.type === WidgetSourceType.FREE_TEXT);

  if (freeTextSource) {
    return getTitleFromMarkdown(freeTextSource.value);
  }

  const namespace = sources
    .filter((source) => source.type === WidgetSourceType.NAMESPACE)
    .map((source) => source.value);

  const attributes = sources.filter(isAttributeSource).map((source) => source.value);

  const customExpressions = sources
    .filter(isYCustomExpressionSource)
    .map((source) => source.metadata.alias);

  const attributePattern = sourcesToAttributePattern(sources);

  const result = [];

  if (namespace?.length) {
    result.push(namespace.join(', '));
  }

  if (attributes?.length) {
    result.push(attributes.join(', '));
  }

  if (customExpressions?.length) {
    result.push(customExpressions.join(', '));
  }

  if (attributePattern) {
    result.push(attributePattern);
  }

  return result.join(', ');
}

export type WidgetNameWithDiff = {
  fullTitle: string;
  shortenedTitle?: Array<string | DIFF_MARKER>;
};

export function inferWidgetNameWithDiff(
  widget: Widget,
  {
    resolvedAttributeNames,
    extractCommonParts = false,
  }: {
    resolvedAttributeNames?: string[];
    extractCommonParts?: boolean;
  } = {},
): WidgetNameWithDiff {
  if (widget.name) {
    return {
      fullTitle: widget.name,
    };
  }

  if (!widget.sources?.length) {
    return {
      fullTitle: 'Unnamed',
    };
  }

  if (!extractCommonParts) {
    return {
      fullTitle: inferNameFromSources(widget.sources),
    };
  }

  const attributesNeedResolving = widget.sources.some(isAttributePatternSource);

  if (attributesNeedResolving && !resolvedAttributeNames?.length) {
    return {
      fullTitle: inferNameFromSources(widget.sources),
    };
  }

  const attributes =
    resolvedAttributeNames ?? widget.sources.filter(isAttributeSource).map(({ value }) => value);

  const commonPartsAndDiffs = findCommonPartsAndDiffs(attributes);

  if (!haveMeaningfulCommonSubsequence(commonPartsAndDiffs)) {
    return {
      fullTitle: inferNameFromSources(widget.sources),
    };
  }

  const nonAttributePart = inferNameFromSources(
    widget.sources.filter((s) => !isAttributeSource(s)),
  );

  return {
    fullTitle: inferNameFromSources(widget.sources),
    shortenedTitle: nonAttributePart
      ? [...commonPartsAndDiffs, `, ${nonAttributePart}`]
      : commonPartsAndDiffs,
  };
}

const meaningfullPartRe = /[\p{L}\p{N}]/u;

export function haveMeaningfulCommonSubsequence(commonPartsAndDiffs: Array<string | DIFF_MARKER>) {
  return commonPartsAndDiffs.some((part) => part !== DIFF_MARKER && meaningfullPartRe.test(part));
}

export function inferWidgetName(widget: Widget) {
  return inferWidgetNameWithDiff(widget).fullTitle;
}

export function extractAttributeDefinitions(widget: Widget): AttributeDefinition[] {
  return widget.sources
    .filter(isAttributeSource)
    .map(
      (source): Partial<AttributeDefinition> => ({
        name: source.value,
        type: source.metadata?.attributeType,
        ...(source.metadata?.subproperty && { subproperty: source.metadata.subproperty }),
      }),
    )
    .filter(
      (definition): definition is AttributeDefinition => !!definition.name && !!definition.type,
    );
}

export function attributeDefinitionToWidgetSource(
  attributeDefinition: AttributeDefinition,
): WidgetSource {
  return {
    type: WidgetSourceType.ATTRIBUTE,
    value: attributeDefinition.name,
    metadata: {
      attributeType: attributeDefinition.type,
      ...(attributeDefinition.subproperty && { subproperty: attributeDefinition.subproperty }),
    },
  };
}
