import React from 'react';

import { useGenericModal } from '@neptune/shared/common-business-logic';
import { EntityChanges, EntityDetailsModalData } from '@neptune/shared/entity-domain';
import { usePermissionEnhancedEntry } from '@neptune/shared/project-business-logic';

import { useEntityChange } from './use-entity-change';
import { useEntityChunk } from './use-entity-chunk';

export const useEntityModalDetails = (
  entityFieldsToFetch: string[],
  modalName: string,
  config: {
    onClose?: () => void;
    onOpen?: () => void;
  } = {},
) => {
  const { data, isOpen, close } = useGenericModal<EntityDetailsModalData>(modalName);
  const { onClose, onOpen } = config;

  React.useEffect(() => {
    if (isOpen) {
      onOpen?.();
    } else {
      onClose?.();
    }
  }, [isOpen, onClose, onOpen]);

  const { isLoading, entity, refresh } = useEntityChunk({
    entityId: data?.entityId,
    entityFieldsToFetch,
    type: 'modelVersion',
  });

  const entityEnhancedEntry = usePermissionEnhancedEntry(entity);

  const entityWithPermissions = React.useMemo(() => {
    return entityEnhancedEntry;
  }, [entityEnhancedEntry]);

  const handleEntityChange = useEntityChange({
    entityId: entityWithPermissions?.id,
    entityType: 'modelVersion',
  });

  const updateEntity = React.useCallback(
    async (entityChanges: Partial<EntityChanges>) => {
      if (!entityWithPermissions?.id) {
        return;
      }

      await handleEntityChange(entityChanges);

      await refresh();
    },
    [refresh, handleEntityChange, entityWithPermissions?.id],
  );

  return React.useMemo(
    () => ({
      updateEntity,
      entityWithPermissions,
      isLoading,
      isOpen,
      closeModal: close,
    }),
    [updateEntity, entityWithPermissions, isLoading, isOpen, close],
  );
};
