import React from 'react';

import {
  queryMapper,
  SearchQueryTerm,
  searchRegexLanguageParser,
} from '@neptune/compound-search-domain';
import { SearchQuery, SearchQueryModelConverter } from '@neptune/search-query-domain';
import { NameSearchMode } from '@neptune/shared/search-domain';

import { KnownAttributes } from 'domain/experiment/attribute';

type NameSearchQueryContextValue = {
  rawRegexpQuery: string;
  regexpQuery: SearchQueryTerm[];
  searchQuery: SearchQuery;
  searchQueryConvertedToLegacyName: SearchQuery;
  searchQueryNQL: string;
  mode: NameSearchMode;
  setQuery: (query: string) => void;
  toggleMode: () => void;
};

const NameSearchQueryContext = React.createContext<NameSearchQueryContextValue | null>(null);

type NameSearchQueryProviderProps = React.PropsWithChildren<{
  rawRegexpQuery: string;
  mode: NameSearchMode;
  onChange: (query: string, mode: NameSearchMode) => void;
}>;

export const NameSearchQueryProvider = React.memo<NameSearchQueryProviderProps>(
  function NameSearchQueryProvider({ rawRegexpQuery, mode, onChange, children }) {
    const setQuery = React.useCallback(
      (newQuery: string) => {
        onChange(newQuery, mode);
      },
      [onChange, mode],
    );

    const toggleMode = React.useCallback(() => {
      const newMode =
        mode === NameSearchMode.SUBSTRING ? NameSearchMode.REGEX : NameSearchMode.SUBSTRING;

      onChange(rawRegexpQuery, newMode);
    }, [mode, onChange, rawRegexpQuery]);

    const regexpQuery = React.useMemo(() => {
      const { query: parsedQuery } = searchRegexLanguageParser.parse(rawRegexpQuery);

      return parsedQuery;
    }, [rawRegexpQuery]);

    const searchQuery = React.useMemo(() => {
      return queryMapper.mapRegexQueryToSearchQuery(regexpQuery);
    }, [regexpQuery]);

    const searchQueryConvertedToLegacyName = React.useMemo(() => {
      const result: SearchQuery = {
        criteria: [
          {
            attribute: KnownAttributes.Name,
            operator: 'contains',
            type: 'string',
            value: rawRegexpQuery,
          },
        ],
        operator: 'and',
      };
      return result;
    }, [rawRegexpQuery]);

    const searchQueryNQL = React.useMemo(
      () => SearchQueryModelConverter.convertSearchQueryToNql(searchQuery),
      [searchQuery],
    );

    return (
      <NameSearchQueryContext.Provider
        value={{
          rawRegexpQuery,
          regexpQuery,
          searchQuery,
          searchQueryNQL,
          searchQueryConvertedToLegacyName,
          mode,
          setQuery,
          toggleMode,
        }}
      >
        {children}
      </NameSearchQueryContext.Provider>
    );
  },
);

export function useNameSearchQuery() {
  const value = React.useContext(NameSearchQueryContext);

  if (!value) {
    throw new Error('useNameSearchQuery must be used within a SearchQueryContext');
  }

  return value;
}
