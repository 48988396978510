import { ADD_TO_COMPARE_LIMIT } from '@neptune/compare-domain';
import {
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';
import {
  LeaderboardEntry,
  LeaderboardQueryNameAliases,
  searchLeaderboard,
} from '@neptune/shared/leaderboard-domain';

import {
  KnownAttributes,
  knownAttributesForExperimentIdentification,
} from 'domain/experiment/attribute';

import { entriesToIdsAndNames } from './utils';

const EXPERIMENT_HEAD_CRITERION: SearchCriterion = {
  value: true,
  type: 'bool',
  operator: '=',
  attribute: KnownAttributes.ExperimentIsHead,
} as const;

export async function resolveFilteredCompareEntries(
  projectIdentifier: string,
  runIds: string[],
  experimentNames: string[],
  query: string,
  experimentsOnly?: boolean,
  queryNameAliases?: LeaderboardQueryNameAliases,
): Promise<LeaderboardEntry[]> {
  const keysCriteria = [
    ...getKeysCriteria(runIds, KnownAttributes.Id),
    ...getKeysCriteria(experimentNames, KnownAttributes.Name),
  ];

  const leaderboardResult = await searchLeaderboard({
    type: ['run'],
    projectIdentifier,
    experimentsOnly,
    queryNameAliases,
    pagination: {
      limit: ADD_TO_COMPARE_LIMIT,
      offset: 0,
    },
    query: SearchQueryModelConverter.convertSearchQueryToNql({
      criteria: [
        { operator: 'or', criteria: keysCriteria },
        SearchQueryModelConverter.convertNqlToSearchQuery(query),
      ],
      operator: 'and',
    }),
    attributesToFetch: knownAttributesForExperimentIdentification,
  });

  return leaderboardResult.entries;
}

export async function resolveFilteredCompareRunIdsAndExperimentNames(
  projectIdentifier: string,
  runIds: string[],
  experimentNames: string[],
  query?: string,
  experimentsOnly?: boolean,
  queryNameAliases?: LeaderboardQueryNameAliases,
): Promise<{ runIds: Set<string>; experimentNames: Set<string> }> {
  if (!query) {
    return { runIds: new Set(runIds), experimentNames: new Set(experimentNames) };
  }

  const entries = await resolveFilteredCompareEntries(
    projectIdentifier,
    runIds,
    experimentNames,
    query,
    experimentsOnly,
    queryNameAliases,
  );

  return entriesToIdsAndNames(entries);
}

export async function resolveFilteredCompareRunIdsAndExperimentNamesArrays(
  projectIdentifier: string,
  runIds: string[],
  experimentNames: string[],
  query?: string,
  experimentsOnly?: boolean,
  queryNameAliases?: LeaderboardQueryNameAliases,
): Promise<{ runIds: string[]; experimentNames: string[] }> {
  if (!query) {
    return { runIds, experimentNames };
  }

  const entries = await resolveFilteredCompareEntries(
    projectIdentifier,
    runIds,
    experimentNames,
    query,
    experimentsOnly,
    queryNameAliases,
  );

  return entriesToIdsAndNames(entries, Array.from);
}

function getKeysCriteria(
  entries: string[],
  attribute: KnownAttributes.Id | KnownAttributes.Name,
): Array<SearchQuery | SearchCriterion> {
  const criteria: SearchCriterion[] = entries.map((value: string) => ({
    type: 'string',
    operator: '=',
    attribute,
    value,
  }));

  if (attribute === KnownAttributes.Name) {
    return criteria.map((criterion) => ({
      criteria: [criterion, EXPERIMENT_HEAD_CRITERION],
      operator: 'and',
    }));
  }

  return criteria;
}
