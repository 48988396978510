import React from 'react';

import { useGroupBy } from '@neptune/shared/leaderboard-business-logic';
import {
  GroupByModelConverter,
  LeaderboardQueryNameAliases,
} from '@neptune/shared/leaderboard-domain';

import { useEntityLeaderboardPolling } from './use-entity-leaderboard-polling';

type UseEntityLeaderboardPollingWithGroupingParams = {
  queryNameAliases?: LeaderboardQueryNameAliases;
  compareIds?: string[];
  selectedEntities?: string[];
  enabled?: boolean;
};

export function useEntityLeaderboardPollingWithGrouping({
  queryNameAliases,
  compareIds,
  selectedEntities,
  enabled,
}: UseEntityLeaderboardPollingWithGroupingParams = {}) {
  const { isEnabled: groupByMode, options: groupByOptions, openedGroups } = useGroupBy();

  const grouping = React.useMemo(() => {
    if (!groupByMode || !groupByOptions) {
      return undefined;
    }

    return {
      groupBy: GroupByModelConverter.fromGroupOptionsToDomain(groupByOptions),
      openedGroups,
      compareIds,
    };
  }, [groupByMode, groupByOptions, openedGroups, compareIds]);

  return useEntityLeaderboardPolling({
    queryNameAliases,
    grouping,
    selectedEntities,
    enabled,
  });
}
