// Libs
import React from 'react';

import { InfoTooltip } from '@neptune/shared/common-ui';
import { Button, ButtonGroup, Dropdown, UITooltip, useTooltip } from '@neptune/shared/venus-ui';

// App
import { ComponentToggle } from 'components/toggles/component-toggle/ComponentToggle';

// Module
interface LbViewsButtonsProps {
  disabled?: boolean;
  canCreate?: boolean;
  canCreateDisabledReason?: React.ReactNode;
  viewType: 'standard' | 'archived' | 'custom';
  onCreate: () => void;
  onUpdate: () => void;
  onDiscard: () => void;
}

export const LbViewsSplitButton: React.FC<LbViewsButtonsProps> = ({
  canCreate = true,
  canCreateDisabledReason,
  disabled,
  viewType,
  onCreate,
  onUpdate,
  onDiscard,
}) => {
  const tooltipProps = useTooltip({
    placement: 'top-center',
    disabled: !disabled,
  });

  const { ref, ...restTriggerProps } = tooltipProps.triggerProps;

  const button = React.useMemo(() => {
    if (viewType === 'standard') {
      return (
        <InfoTooltip show={!canCreate} placement="right-center" text={canCreateDisabledReason}>
          <Button
            size="lg"
            disabled={!canCreate}
            data-role="save-as-new-button"
            children="Save view as new"
            onClick={onCreate}
          />
        </InfoTooltip>
      );
    }

    if (viewType === 'custom') {
      return (
        <Button
          size="lg"
          disabled={disabled}
          data-role="save-button"
          children="Save view"
          onClick={onUpdate}
        />
      );
    }

    return null;
  }, [viewType, canCreate, canCreateDisabledReason, onCreate, onUpdate, disabled]);

  return (
    <>
      <ButtonGroup inline span="auto" elementRef={ref} {...restTriggerProps}>
        {button}
        <Dropdown
          disabled={disabled}
          toggle={
            <ComponentToggle
              component={Button}
              disabled={disabled}
              data-role="lb-views-split-button-toggle"
              square
              size="lg"
              children={<Dropdown.Toggle.Icon />}
            />
          }
          menuAnimation={Dropdown.DROPDOWN_ANIMATION_DOWN}
          constraints={Dropdown.CONSTRAINT_TO_WINDOW_NO_PIN}
        >
          {({ collapse: collapseMenu }) => (
            <Dropdown.Menu>
              {viewType === 'custom' && (
                <InfoTooltip
                  show={!canCreate}
                  placement="right-center"
                  text={canCreateDisabledReason}
                >
                  <Dropdown.Item
                    disabled={!canCreate}
                    onClick={() => {
                      onCreate();
                      collapseMenu();
                    }}
                    data-role="save-new-button"
                    label="Save as new"
                  />
                </InfoTooltip>
              )}
              <Dropdown.Item
                onClick={() => {
                  onDiscard();
                  collapseMenu();
                }}
                data-role="discard-link"
                label="Discard changes"
              />
            </Dropdown.Menu>
          )}
        </Dropdown>
      </ButtonGroup>
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(
          <UITooltip {...tooltipProps.layerProps}>
            You need to be a project owner or contributor to save custom views.
          </UITooltip>,
        )}
    </>
  );
};
