import React from 'react';
import { uniqueId } from 'lodash';

import {
  findSearchesMatchingTerms,
  isValidSearchQuery,
  RecentSearch,
  SearchQueryTerm,
  SearchQueryTermType,
} from '@neptune/compound-search-domain';
import {
  ComplexSearchFilter,
  ComplexSearchFilterConverter,
  CompoundSearchCriterion,
  isEmptyFilter,
  isRegexFilter,
  trimQuery,
} from '@neptune/recent-searches-domain';
import { NameSearchMode } from '@neptune/shared/search-domain';

import { useRecentCompoundQueries } from './use-recent-search-queries';
import { useRecentSearchesMemos } from './use-recent-searches-memos';

type UseCompoundSearchHistoryReturnValue = {
  /**
   * @deprecated Use searchesMatchingQuery instead
   */
  searches: ComplexSearchFilter[];
  /**
   * @deprecated Use saveRegexpSearch instead
   */
  handleSaveSearch: (filter?: ComplexSearchFilter) => void;
  searchesMatchingQuery: RecentSearch[];
  saveRegexpSearch: (filter: SearchQueryTerm[]) => void;
};

export const useCompoundSearchHistory = ({
  namespace,
  mode,
  queryToMatch,
}: {
  namespace: string;
  mode: NameSearchMode;
  queryToMatch?: SearchQueryTerm[];
}): UseCompoundSearchHistoryReturnValue => {
  const { searches: textSearches, saveSearch: saveTextSearch } = useRecentSearchesMemos({
    namespace,
  });

  const { searches: compoundSearches, saveSearch: saveCompoundSearch } = useRecentCompoundQueries({
    namespace: `${namespace}-compound`,
  });

  const textSearchesFilters: ComplexSearchFilter[] = React.useMemo(
    () => textSearches.map(ComplexSearchFilterConverter.fromChartFilter),
    [textSearches],
  );

  const compoundSearchFilters: ComplexSearchFilter[] = React.useMemo(
    () => compoundSearches.map(ComplexSearchFilterConverter.fromRegexCriterions),
    [compoundSearches],
  );

  const searches = mode === NameSearchMode.REGEX ? compoundSearchFilters : textSearchesFilters;

  const handleSaveSearch = React.useCallback(
    (filter?: ComplexSearchFilter): void => {
      if (filter && !isEmptyFilter(filter)) {
        if (isRegexFilter(filter)) {
          const newValue = trimQuery(filter.value);
          saveCompoundSearch(newValue, areSearchesEqual);
        } else {
          saveTextSearch(filter.value);
        }
      }
    },
    [saveCompoundSearch, saveTextSearch],
  );

  const searchesMatchingQuery = React.useMemo(() => {
    const allRecentRegexSearches = searches.reduce<RecentSearch[]>((acc, search) => {
      if (search.searchType !== 'regex') {
        return acc;
      }

      return [...acc, { id: uniqueId(), terms: fromCriterionsToTerms(search.value) }];
    }, []);

    if (!queryToMatch) {
      return allRecentRegexSearches.filter(isValidRecentSearch);
    }

    return findSearchesMatchingTerms(queryToMatch, allRecentRegexSearches).filter(
      isValidRecentSearch,
    );
  }, [queryToMatch, searches]);

  const saveRegexpSearch = React.useCallback(
    (query: SearchQueryTerm[]) => {
      handleSaveSearch({
        searchType: NameSearchMode.REGEX,
        value: fromTermsToCriterions(query),
      });
    },
    [handleSaveSearch],
  );

  return {
    searches,
    handleSaveSearch,
    searchesMatchingQuery,
    saveRegexpSearch,
  };
};

function fromCriterionsToTerms(criterions: CompoundSearchCriterion[]): SearchQueryTerm[] {
  return criterions.map(({ type, value }) => ({
    type: type === 'regex' ? SearchQueryTermType.CRITERION : SearchQueryTermType.OPERATOR,
    value,
  }));
}

function fromTermsToCriterions(terms: SearchQueryTerm[]): CompoundSearchCriterion[] {
  return terms.map(({ type, value }) => ({
    type: type === SearchQueryTermType.CRITERION ? 'regex' : 'operator',
    value,
  }));
}

function isValidRecentSearch(recentSearch: RecentSearch) {
  return isValidSearchQuery(recentSearch.terms);
}

function areSearchesEqual(a: CompoundSearchCriterion[], b: CompoundSearchCriterion[]) {
  const aText = a.map((criterion) => criterion.value).join(' ');
  const bText = b.map((criterion) => criterion.value).join(' ');
  return aText === bText;
}
