import { last } from 'lodash';

import { searchRegexLanguageParser } from '../parser';
import { SearchQueryTerm, SearchQueryTermType } from '../types';

import { mergeQueries } from './merge-queries';
import { areTermsSameType, isAndNotOperator, isAndOperator, isNotOperator } from './utils';

const withoutLastNTerms = (query: SearchQueryTerm[], n: number) => {
  return query.slice(0, n ? -n : undefined);
};

const mergeTermWithQueries = (
  queryUpToCursor: SearchQueryTerm[],
  query: SearchQueryTerm[],
  newTerm: SearchQueryTerm,
  keepLastTerm: boolean,
): string => {
  const queryToTake = withoutLastNTerms(queryUpToCursor, keepLastTerm ? 0 : 1);
  const mergedQueries = mergeQueries([...queryToTake, newTerm], query);
  return searchRegexLanguageParser.serialize(mergedQueries);
};

export const insertTermAtCursorPosition = (
  rawQuery: string,
  cursorPosition: number,
  newTerm: SearchQueryTerm,
) => {
  const rawQueryUpToCursor = rawQuery.substring(0, cursorPosition);

  const { query: queryUpToCursor } = searchRegexLanguageParser.parse(rawQueryUpToCursor);
  const { query: query } = searchRegexLanguageParser.parse(rawQuery);

  const lastCursorTerm = last(queryUpToCursor);
  const hasFinalSpace = last(rawQuery) === ' ';
  const isCursorAtTheEnd = rawQuery.length === cursorPosition;

  if (
    isNotOperator(newTerm) &&
    (isAndOperator(lastCursorTerm) || isAndNotOperator(lastCursorTerm))
  ) {
    return mergeTermWithQueries(
      queryUpToCursor,
      query,
      {
        type: SearchQueryTermType.OPERATOR,
        value: 'AND NOT',
      },
      false,
    );
  }

  if (isCursorAtTheEnd && areTermsSameType(lastCursorTerm, newTerm) && hasFinalSpace) {
    return rawQuery;
  }

  const keepLastTerm = !areTermsSameType(lastCursorTerm, newTerm);

  return mergeTermWithQueries(queryUpToCursor, query, newTerm, keepLastTerm);
};
