import React from 'react';

import { StrokePatternsContext } from '@neptune/compare-domain';

export function createStrokePatternsContext(name: string) {
  const StrokePatternsContext = React.createContext<StrokePatternsContext>({
    patterns: new Map(),
    makeStrokePatternKey: () => '',
    getStrokePattern: () => undefined,
  });

  function useStrokePatternsContext() {
    return React.useContext(StrokePatternsContext);
  }

  StrokePatternsContext.displayName = name;

  return { StrokePatternsContext, useStrokePatternsContext };
}
