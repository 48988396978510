import { last } from 'lodash';

import { SearchCriterion, SearchQuery, StringCriterion } from '@neptune/search-query-domain';

import { SearchQueryTerm, SearchQueryTermType } from './types';

function splitArrayBy<T>(inputArray: T[], splitByFn: (element: T) => boolean): T[][] {
  return inputArray.reduce(
    (result: T[][], value: T) => {
      if (splitByFn(value)) {
        result.push([]);
      } else {
        if (result.length === 0) {
          result.push([value]);
        } else {
          result[result.length - 1].push(value);
        }
      }

      return result;
    },
    [[]],
  );
}

function isOrOperator(term: SearchQueryTerm) {
  return term.type === SearchQueryTermType.OPERATOR && term.value === 'OR';
}

export const createQueryMapper = () => {
  function mapRegexQueryToSearchQuery(regexQuery: SearchQueryTerm[]): SearchQuery {
    const sanitizedQuery = sanitizeQuery(regexQuery);
    const splitRegexQueryByOR = splitArrayBy(sanitizedQuery, isOrOperator);

    if (splitRegexQueryByOR.length === 0) {
      return {
        criteria: [],
        operator: 'and',
      };
    }

    if (splitRegexQueryByOR.length === 1) {
      return {
        criteria: buildCriteriaFromRegexQuery(sanitizedQuery),
        operator: 'and',
      };
    }

    return {
      criteria: splitRegexQueryByOR.map((subQuery) =>
        subQuery.length > 1
          ? mapRegexQueryToSearchQuery(subQuery)
          : createStringCriterion(subQuery[0].value, false),
      ),
      operator: 'or',
    };
  }

  function sanitizeQuery(regexQuery: SearchQueryTerm[]) {
    const lastTerm = last(regexQuery);

    if (lastTerm?.type === SearchQueryTermType.OPERATOR) {
      return regexQuery.slice(0, -1);
    }

    return regexQuery;
  }

  function buildCriteriaFromRegexQuery(regexQuery: SearchQueryTerm[]) {
    return regexQuery.reduce<SearchCriterion[]>((acc, term, index) => {
      if (term.type !== SearchQueryTermType.CRITERION) {
        return acc;
      }

      const previousTerm = regexQuery[index - 1];
      const isNegated =
        previousTerm &&
        previousTerm.type === SearchQueryTermType.OPERATOR &&
        previousTerm.value === 'AND NOT';

      const criterion = createStringCriterion(term.value, isNegated);
      return [...acc, criterion];
    }, []);
  }

  function createStringCriterion(value: string, isNegated: boolean): StringCriterion {
    return {
      attribute: 'sys/name',
      type: 'string',
      operator: isNegated ? '!matches' : 'matches',
      value,
    };
  }

  return { mapRegexQueryToSearchQuery };
};

export const queryMapper = createQueryMapper();
