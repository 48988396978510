import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Table } from 'neptune-core-ui/components';

// TODO: introduce UserProfile domain layer for UserProfileDTO
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UserProfileDTO } from '@neptune/shared/core-apis-backend-domain';
import { bemBlock, Section } from '@neptune/shared/venus-ui';
import { BaseMember } from '@neptune/user-management-domain';

import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { fetchStatus as FetchStatus } from 'state/fetch-status';

import {
  DeleteButtonRenderer,
  DeleteCallbackParams,
} from './member-list-renderer/DeleteButtonRenderer';
import { FullNameRenderer } from './member-list-renderer/FullNameRenderer';
import { PendingInvitationRenderer } from './member-list-renderer/PendingInvitationRenderer';
import { ProjectsAccessRenderer } from './member-list-renderer/ProjectsAccessRenderer';
import { RoleDropdownItemProps, RoleRenderer } from './member-list-renderer/RoleRenderer';
import { UsernameRenderer } from './member-list-renderer/UsernameRenderer';

import './MemberList.less';

export enum MemberListContext {
  project = 'project',
  workspace = 'workspace',
}

type MemberListProps<T extends BaseMember> = {
  entries: T[];
  renderRoleDropdown: React.ComponentType<RoleDropdownItemProps<T['role']>>;
  onMemberDelete?: (params: DeleteCallbackParams) => void;
  onInviteResend?: (invitationId: string) => void;
  canEdit?: boolean;
  onSort: (sortOptions: { sortBy: string; sortOrder: string }) => void;
  context: MemberListContext;
  'data-role'?: string;
  fetchStatus: FetchStatus;
  user: UserProfileDTO | null;
  sortOptions: { sortBy: string; sortOrder: string };
  projectAccessTooltipText?: string;
};

const block = bemBlock('member-list');

export const MemberList = <T extends BaseMember>({
  entries = [],
  fetchStatus,
  user,
  renderRoleDropdown,
  sortOptions,
  onSort,
  onMemberDelete,
  context,
  projectAccessTooltipText,
  onInviteResend,
  canEdit,
  'data-role': dataRole,
}: MemberListProps<T>) => {
  const { sortBy, sortOrder } = sortOptions;

  const columns = [
    {
      head: {
        data: 'Username',
        actions: {
          sort: {
            sortBy: 'username',
            sortOrder: sortBy === 'username' ? sortOrder : 'none',
            onSortChange: onSort,
          },
        },
      },
      body: {
        renderer: UsernameRenderer,
        width: 250,
        renderParams: {
          user,
          pendingInviteBadgeEnabled: context === MemberListContext.project,
        },
      },
    },
    {
      head: {
        data: 'Name',
        actions: {
          sort: {
            sortBy: 'lastName',
            sortOrder: sortBy === 'lastName' ? sortOrder : 'none',
            onSortChange: onSort,
          },
        },
      },
      body: { renderer: FullNameRenderer, width: 250 },
    },
    ...(context === MemberListContext.workspace
      ? [
          {
            head: {
              data: 'Projects access',
            },
            body: {
              renderer: ProjectsAccessRenderer,
              renderParams: {
                projectAccessTooltipText,
              },
              width: 174,
            },
          },
          {
            head: {
              data: 'Workspace role',
              actions: {
                sort: {
                  sortBy: 'role',
                  sortOrder: sortBy === 'role' ? sortOrder : 'none',
                  onSortChange: onSort,
                },
              },
            },
            body: {
              width: 120,
              renderer: RoleRenderer,
              renderParams: {
                renderRoleDropdown,
              },
            },
          },
          {
            head: { data: '' },
            body: {
              renderer: PendingInvitationRenderer,
              renderParams: {
                onInviteResend,
                canEdit,
              },
              width: 250,
            },
          },
        ]
      : [
          {
            head: {
              data: 'Project role',
              actions: {
                sort: {
                  sortBy: 'role',
                  sortOrder: sortBy === 'role' ? sortOrder : 'none',
                  onSortChange: onSort,
                },
              },
            },
            body: {
              width: 150,
              renderer: RoleRenderer,
              renderParams: {
                renderRoleDropdown,
              },
            },
          },
        ]),
    {
      body: {
        width: 44,
        renderer: DeleteButtonRenderer,
        renderParams: {
          user,
          onClick: onMemberDelete,
        },
      },
    },
  ];

  return (
    <Section data-role={dataRole}>
      <Table
        data={entries}
        columns={columns}
        renderParts={['head', 'body']}
        className={block('table')}
      />
      <LoadingIndicator fetchStatus={fetchStatus} className={block('loading')} />
    </Section>
  );
};
