import React from 'react';
import { bufferTime, delay, filter } from 'rxjs';

import { useUnderEditContext, withUnderEditFilter } from '@neptune/shared/common-business-logic';
import { EntityLeaderboardNotification } from '@neptune/shared/entity-leaderboard-domain';

import { getEntityLeaderboardNotificationStream } from './entity-leaderboard-notifications';

type HookParams = {
  projectId?: string;
  entityTypes: string[];
  onNotify: (notifications: EntityLeaderboardNotification[]) => void;
};

export const useEntityLeaderboardChangedNotifications = ({
  projectId,
  entityTypes,
  onNotify,
}: HookParams) => {
  const { underEdit$ } = useUnderEditContext();

  React.useEffect(() => {
    if (!projectId) {
      return;
    }

    const subscription = getEntityLeaderboardNotificationStream({
      projectId,
      entityTypes,
    })
      .pipe(
        bufferTime(1200),
        delay(300),
        filter((notifications) => !!notifications.length),
        withUnderEditFilter(underEdit$),
      )
      .subscribe(onNotify);

    return () => subscription.unsubscribe();
  }, [projectId, entityTypes, onNotify, underEdit$]);
};
