import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';

/**
 * @see './short-id-compression.md'
 */
export abstract class ShortIdCompress {
  static stringify(ids: string[]): string {
    return compressToEncodedURIComponent(
      ids
        .map((id) => id.replace(/^.*-/, ''))
        .map((s: string) => +s)
        .sort((a, b) => a - b)
        .reduce((result: number[], n) => {
          if (n !== result[result.length - 1]) {
            result.push(n);
          }

          return result;
        }, [])
        .map((id, i, all) => (i ? id - all[i - 1] : id))
        .join(),
    );
  }

  static parse(serialized: string, prefix: string): string[] {
    const decompressed = decompressFromEncodedURIComponent(serialized);

    if (!decompressed || !decompressed.match(/^[0-9,]*$/)) {
      return [];
    }

    return decompressed
      .split(',')
      .map((s: string) => +s)
      .reduce((result: number[], n: number, i: number) => {
        result.push(i ? result[i - 1] + n : n);
        return result;
      }, [])
      .map((s: number) => `${prefix}-${s}`);
  }
}
