import React from 'react';
import { noop } from 'lodash';

import { RunIdentificationPair } from '@neptune/shared/entity-domain';

type CompareContext = {
  /** Number of all items selected to compare */
  count: number;
  /** Number of selected items filtered by query */
  hiddenCount: number;
  /** Number of selected items matching query */
  selectedCount: number;
  /** Array of sys/id that are selected to compare */
  runIds: string[];
  /** Limit of items that can be selected automatically */
  autoCompareLimit?: number;
  /** Array of sys/id and sys/names. Runs that are selected and have sys/name will be translated to sys/id and both values will be added */
  selectedKeys: string[];
  /** Flag that indicates if user can modify items selected compare */
  canEditCompare: boolean;
  /** sys/name and run/id array selected to compare. sys/name has higher precedence it will be present in this array instead of sys/id */
  knownIdsAndNames: string[];
  /** Map of run id to experiment name, useful to determine if something was selected as experiment or run */
  runIdToExperimentName: Record<string, string>;
  showOnlySelectedRuns?: boolean;
  showSelectedHiddenByFilter?: boolean;
  runIdsReady?: boolean;
  clearCompare: () => void;
  autoModeChange: (n?: number) => void;
  showOnlySelectedRunsChange?: (value: boolean) => void;
  showSelectedHiddenByFilterChange?: (value: boolean) => void;
  addToCompare: (runIdentificationPairs: RunIdentificationPair[]) => void;
  removeFromCompare: (runIdentificationPairs: RunIdentificationPair[]) => void;
};

export const CompareContext = React.createContext<CompareContext>({
  count: 0,
  hiddenCount: 0,
  selectedCount: 0,
  runIds: [],
  autoCompareLimit: undefined,
  selectedKeys: [],
  knownIdsAndNames: [],
  runIdToExperimentName: {},
  clearCompare: noop,
  addToCompare: noop,
  autoModeChange: noop,
  removeFromCompare: noop,
  canEditCompare: false,
  showSelectedHiddenByFilter: false,
  showSelectedHiddenByFilterChange: noop,
  showOnlySelectedRuns: false,
  showOnlySelectedRunsChange: noop,
});
