import { isEmpty } from 'lodash';

import { getConfigOrderInversed } from 'common/featureFlag';
import { Complete } from 'common/utility-types';
import { AttributeDefinition } from 'domain/experiment/attribute';

import type { ConflictDictionary } from './charts-conflicts';

export type XAxisMode = 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
export type AxisScale = 'linear' | 'logarithmic';
export type AxisRange = [number | undefined, number | undefined];
export type JsonCompatibleAxisRange = [number | undefined | null, number | undefined | null];
export type PreviewPointsOption = 'on' | 'off';

export function flattenUndefinedRange(range: AxisRange | undefined) {
  if (range && range[0] == null && range[1] == null) {
    return undefined;
  }

  return range;
}

export function removeNullsFromAxisRange(
  range: JsonCompatibleAxisRange | undefined | null,
): AxisRange | undefined {
  return (range && [range[0] ?? undefined, range[1] ?? undefined]) ?? undefined;
}

export function getIncludePreview(option: PreviewPointsOption | undefined) {
  // Interpret undefined as enabled.
  return option !== 'off';
}

export function getPreviewPointsOption(
  includePreview: boolean | undefined,
): PreviewPointsOption | undefined {
  if (includePreview == null) {
    return undefined;
  }

  return includePreview ? 'on' : 'off';
}

export function areLocalSettingsSet(config: Partial<ChartConfigurationSettings>) {
  const { customXExpression, customXExpressionName, ...remainingSettings } = config;

  const defaultOptions = getConfigOrderInversed()
    ? DEFAULT_CHART_GLOBAL_CONFIG_FOR_NEW_GLOBAL_CONFIG_BEHAVIOUR
    : DEFAULT_CHART_LOCAL_CONFIG;

  // Handle edge case where customX fields were saved as empty strings
  if (!isEmpty(customXExpression) && !isEmpty(customXExpressionName)) {
    return true;
  }

  for (const [key, value] of Object.entries(remainingSettings)) {
    if (Object.hasOwnProperty.call(defaultOptions, key)) {
      const defaultValue = defaultOptions[key as keyof typeof defaultOptions];

      if (value !== undefined && value !== defaultValue) {
        return true;
      }
    }
  }

  return false;
}

export const isAxisScale = (input: string): input is AxisScale =>
  input === 'linear' || input === 'logarithmic';

export type AxisRangeHandlerParams = {
  min: number | undefined;
  max: number | undefined;
};

export type ChartConfigurationProps = ChartConfigurationSettings & {
  onSetYLogScale: (scale: AxisScale) => void;
  onSetXLogScale: (scale: AxisScale) => void;
  setXAxisMode?: (xAxisMode: XAxisMode) => void;
  setXAxisMetric?: (xAxisMetric: AttributeDefinition | undefined) => void;
  setYAxisRange?: (values: AxisRangeHandlerParams) => void;
  setXAxisRange?: (values: AxisRangeHandlerParams) => void;
  selectedAttributes?: AttributeDefinition[];
  conflicts?: ConflictDictionary;
};

type ChartLocalConfigurationSettings = {
  averaging?: boolean;
  xAxisScale: AxisScale;
  yAxisScale: AxisScale;
  xAxisMode: XAxisMode;
  xAxisMetric?: AttributeDefinition;
  yAxisRange?: AxisRange;
  xAxisRange?: AxisRange;
  customXExpression?: string;
  customXExpressionName?: string;
  smoothing?: number;
  previewPoints?: PreviewPointsOption; // Read using includePreview() function to apply correct default.
  includePreview?: undefined;
};

export type ChartConfigurationSettings = ChartLocalConfigurationSettings & {
  metricsStepsRange?: AxisRange;
};

const DEFAULT_CHART_LOCAL_CONFIG: Complete<
  Omit<ChartLocalConfigurationSettings, 'includePreview'>
> = {
  smoothing: undefined,
  averaging: false,
  xAxisMode: 'step',
  xAxisScale: 'linear',
  yAxisScale: 'linear',
  xAxisMetric: undefined,
  yAxisRange: undefined,
  xAxisRange: undefined,
  customXExpression: undefined,
  customXExpressionName: undefined,
  previewPoints: undefined,
};

export const DEFAULT_CHART_GLOBAL_CONFIG_FOR_NEW_GLOBAL_CONFIG_BEHAVIOUR: Partial<ChartLocalConfigurationSettings> =
  {
    smoothing: undefined,
    averaging: false,
    xAxisMode: 'step',
    xAxisScale: undefined,
    yAxisScale: undefined,
    xAxisMetric: undefined,
    yAxisRange: undefined,
    xAxisRange: undefined,
    customXExpression: undefined,
    customXExpressionName: undefined,
    previewPoints: undefined,
  };

export const DEFAULT_CHART_CONFIG: Complete<Omit<ChartConfigurationSettings, 'includePreview'>> = {
  ...DEFAULT_CHART_LOCAL_CONFIG,
  metricsStepsRange: undefined,
};

export const prefillAttributes: AttributeDefinition[] = [
  { name: 'step', type: 'floatSeries', predefined: true },
  { name: 'relative time', type: 'datetime', predefined: true },
  { name: 'absolute time', type: 'datetime', predefined: true },
];

export const attributeNameToXAxisMode: Record<string, XAxisMode | undefined> = {
  step: 'step',
  'relative time': 'relativeTime',
  'absolute time': 'absoluteTime',
};

const xAxisModeToAttributeName: Record<string, string | undefined> = {
  step: 'step',
  relativeTime: 'relative time',
  absoluteTime: 'absolute time',
};

export function getSelectedMetricName(xAxisMode: XAxisMode, xAxisMetric?: AttributeDefinition) {
  if (xAxisModeToAttributeName[xAxisMode]) {
    return xAxisModeToAttributeName[xAxisMode];
  }

  return xAxisMetric?.name;
}
