import React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import { auditTime, delay, map } from 'rxjs';

import { useUnderEditContext, withUnderEditFilter } from '@neptune/shared/common-business-logic';
import {
  getEntitiesNotificationStream,
  MetadataChangedNotification,
} from '@neptune/shared/entity-business-logic';
import { EntityType } from '@neptune/shared/entity-domain';

import { updateBasicCurrentEntity } from './redux/actions';

type UseEntityUpdatesParams = {
  projectId?: string;
  entityId?: string;
  entityType: EntityType;
};

export function useBasicEntityUpdates(
  { projectId, entityId, entityType }: UseEntityUpdatesParams,
  callback: () => void = noop,
) {
  const dispatch = useDispatch();

  const { underEdit$ } = useUnderEditContext();

  React.useEffect(() => {
    if (!entityId || !projectId) {
      return;
    }

    const updateEntityFromNotification = (notification: MetadataChangedNotification) => {
      callback();

      return updateBasicCurrentEntity({
        id: notification.messageBody.id,
        type: entityType,
      });
    };

    const subscription = getEntitiesNotificationStream(projectId, [entityId])
      .pipe(
        auditTime(1200),
        delay(300),
        withUnderEditFilter(underEdit$),
        map(updateEntityFromNotification),
      )
      .subscribe(dispatch);

    return () => subscription.unsubscribe();
  }, [callback, dispatch, entityId, entityType, projectId, underEdit$]);
}
