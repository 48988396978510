import React from 'react';
import { useDispatch } from 'react-redux';
import { auditTime, delay, map } from 'rxjs';

import { useUnderEditContext, withUnderEditFilter } from '@neptune/shared/common-business-logic';
import { getEntitiesNotificationStream } from '@neptune/shared/entity-business-logic';
import { EntityType } from '@neptune/shared/entity-domain';

import { updateCurrentEntityAttributeDefinitions } from './redux/actions';

type UseEntityUpdatesParams = {
  entityId?: string;
  entityType: EntityType;
  projectId?: string;
};

export function useEntityAttributeDefinitionsUpdates({
  entityId,
  entityType,
  projectId,
}: UseEntityUpdatesParams) {
  const dispatch = useDispatch();

  const { underEdit$ } = useUnderEditContext();

  React.useEffect(() => {
    if (!entityId || !projectId) {
      return;
    }

    const subscription = getEntitiesNotificationStream(projectId, [entityId])
      .pipe(
        auditTime(1200),
        delay(300),
        withUnderEditFilter(underEdit$),
        map((notification) => updateCurrentEntityAttributeDefinitions(notification.messageBody.id)),
      )
      .subscribe(dispatch);

    return () => subscription.unsubscribe();
  }, [dispatch, entityId, entityType, projectId, underEdit$]);
}
