// Generated from SearchRegexParser.g4 by ANTLR 4.8
// jshint ignore: start
import antlr4 from 'antlr4/index';

var grammarFileName = "SearchRegexParser.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\u000b\"\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0002\u0007\u0002\r",
    "\n\u0002\f\u0002\u000e\u0002\u0010\u000b\u0002\u0003\u0002\u0003\u0002",
    "\u0003\u0003\u0003\u0003\u0005\u0003\u0016\n\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0005\u0003\u001b\n\u0003\u0003\u0003\u0005\u0003",
    "\u001e\n\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0002\u0002\u0005",
    "\u0002\u0004\u0006\u0002\u0003\u0003\u0002\u0004\u0005\u0002$\u0002",
    "\b\u0003\u0002\u0002\u0002\u0004\u001d\u0003\u0002\u0002\u0002\u0006",
    "\u001f\u0003\u0002\u0002\u0002\b\u000e\u0005\u0004\u0003\u0002\t\n\u0005",
    "\u0006\u0004\u0002\n\u000b\u0005\u0004\u0003\u0002\u000b\r\u0003\u0002",
    "\u0002\u0002\f\t\u0003\u0002\u0002\u0002\r\u0010\u0003\u0002\u0002\u0002",
    "\u000e\f\u0003\u0002\u0002\u0002\u000e\u000f\u0003\u0002\u0002\u0002",
    "\u000f\u0011\u0003\u0002\u0002\u0002\u0010\u000e\u0003\u0002\u0002\u0002",
    "\u0011\u0012\u0007\u0002\u0002\u0003\u0012\u0003\u0003\u0002\u0002\u0002",
    "\u0013\u0015\u0007\u0006\u0002\u0002\u0014\u0016\u0007\b\u0002\u0002",
    "\u0015\u0014\u0003\u0002\u0002\u0002\u0015\u0016\u0003\u0002\u0002\u0002",
    "\u0016\u001e\u0003\u0002\u0002\u0002\u0017\u001e\u0007\u0007\u0002\u0002",
    "\u0018\u001a\u0007\t\u0002\u0002\u0019\u001b\u0007\u000b\u0002\u0002",
    "\u001a\u0019\u0003\u0002\u0002\u0002\u001a\u001b\u0003\u0002\u0002\u0002",
    "\u001b\u001e\u0003\u0002\u0002\u0002\u001c\u001e\u0007\n\u0002\u0002",
    "\u001d\u0013\u0003\u0002\u0002\u0002\u001d\u0017\u0003\u0002\u0002\u0002",
    "\u001d\u0018\u0003\u0002\u0002\u0002\u001d\u001c\u0003\u0002\u0002\u0002",
    "\u001e\u0005\u0003\u0002\u0002\u0002\u001f \t\u0002\u0002\u0002 \u0007",
    "\u0003\u0002\u0002\u0002\u0006\u000e\u0015\u001a\u001d"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [  ];

var symbolicNames = [ null, "WS", "AND", "OR", "NOT", "CRITERION", "NEGATED_CRITERION", 
                      "NOT_AFTER_OPERATOR", "CRITERION_AFTER_OPERATOR", 
                      "NEGATED_CRITERION_AFTER_OPERATOR" ];

var ruleNames =  [ "query", "criterion", "operator" ];

function SearchRegexParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

SearchRegexParser.prototype = Object.create(antlr4.Parser.prototype);
SearchRegexParser.prototype.constructor = SearchRegexParser;

Object.defineProperty(SearchRegexParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

SearchRegexParser.EOF = antlr4.Token.EOF;
SearchRegexParser.WS = 1;
SearchRegexParser.AND = 2;
SearchRegexParser.OR = 3;
SearchRegexParser.NOT = 4;
SearchRegexParser.CRITERION = 5;
SearchRegexParser.NEGATED_CRITERION = 6;
SearchRegexParser.NOT_AFTER_OPERATOR = 7;
SearchRegexParser.CRITERION_AFTER_OPERATOR = 8;
SearchRegexParser.NEGATED_CRITERION_AFTER_OPERATOR = 9;

SearchRegexParser.RULE_query = 0;
SearchRegexParser.RULE_criterion = 1;
SearchRegexParser.RULE_operator = 2;


function QueryContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SearchRegexParser.RULE_query;
    return this;
}

QueryContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
QueryContext.prototype.constructor = QueryContext;

QueryContext.prototype.criterion = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(CriterionContext);
    } else {
        return this.getTypedRuleContext(CriterionContext,i);
    }
};

QueryContext.prototype.EOF = function() {
    return this.getToken(SearchRegexParser.EOF, 0);
};

QueryContext.prototype.operator = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(OperatorContext);
    } else {
        return this.getTypedRuleContext(OperatorContext,i);
    }
};




SearchRegexParser.QueryContext = QueryContext;

SearchRegexParser.prototype.query = function() {

    var localctx = new QueryContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, SearchRegexParser.RULE_query);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 6;
        this.criterion();
        this.state = 12;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===SearchRegexParser.AND || _la===SearchRegexParser.OR) {
            this.state = 7;
            this.operator();
            this.state = 8;
            this.criterion();
            this.state = 14;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
        this.state = 15;
        this.match(SearchRegexParser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function CriterionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SearchRegexParser.RULE_criterion;
    return this;
}

CriterionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CriterionContext.prototype.constructor = CriterionContext;

CriterionContext.prototype.NOT = function() {
    return this.getToken(SearchRegexParser.NOT, 0);
};

CriterionContext.prototype.NEGATED_CRITERION = function() {
    return this.getToken(SearchRegexParser.NEGATED_CRITERION, 0);
};

CriterionContext.prototype.CRITERION = function() {
    return this.getToken(SearchRegexParser.CRITERION, 0);
};

CriterionContext.prototype.NOT_AFTER_OPERATOR = function() {
    return this.getToken(SearchRegexParser.NOT_AFTER_OPERATOR, 0);
};

CriterionContext.prototype.NEGATED_CRITERION_AFTER_OPERATOR = function() {
    return this.getToken(SearchRegexParser.NEGATED_CRITERION_AFTER_OPERATOR, 0);
};

CriterionContext.prototype.CRITERION_AFTER_OPERATOR = function() {
    return this.getToken(SearchRegexParser.CRITERION_AFTER_OPERATOR, 0);
};




SearchRegexParser.CriterionContext = CriterionContext;

SearchRegexParser.prototype.criterion = function() {

    var localctx = new CriterionContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, SearchRegexParser.RULE_criterion);
    var _la = 0; // Token type
    try {
        this.state = 27;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case SearchRegexParser.NOT:
            this.enterOuterAlt(localctx, 1);
            this.state = 17;
            this.match(SearchRegexParser.NOT);
            this.state = 19;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===SearchRegexParser.NEGATED_CRITERION) {
                this.state = 18;
                this.match(SearchRegexParser.NEGATED_CRITERION);
            }

            break;
        case SearchRegexParser.CRITERION:
            this.enterOuterAlt(localctx, 2);
            this.state = 21;
            this.match(SearchRegexParser.CRITERION);
            break;
        case SearchRegexParser.NOT_AFTER_OPERATOR:
            this.enterOuterAlt(localctx, 3);
            this.state = 22;
            this.match(SearchRegexParser.NOT_AFTER_OPERATOR);
            this.state = 24;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
            if(_la===SearchRegexParser.NEGATED_CRITERION_AFTER_OPERATOR) {
                this.state = 23;
                this.match(SearchRegexParser.NEGATED_CRITERION_AFTER_OPERATOR);
            }

            break;
        case SearchRegexParser.CRITERION_AFTER_OPERATOR:
            this.enterOuterAlt(localctx, 4);
            this.state = 26;
            this.match(SearchRegexParser.CRITERION_AFTER_OPERATOR);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function OperatorContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SearchRegexParser.RULE_operator;
    return this;
}

OperatorContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
OperatorContext.prototype.constructor = OperatorContext;

OperatorContext.prototype.AND = function() {
    return this.getToken(SearchRegexParser.AND, 0);
};

OperatorContext.prototype.OR = function() {
    return this.getToken(SearchRegexParser.OR, 0);
};




SearchRegexParser.OperatorContext = OperatorContext;

SearchRegexParser.prototype.operator = function() {

    var localctx = new OperatorContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, SearchRegexParser.RULE_operator);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 29;
        _la = this._input.LA(1);
        if(!(_la===SearchRegexParser.AND || _la===SearchRegexParser.OR)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


SearchRegexParser.QueryContext = QueryContext;
SearchRegexParser.CriterionContext = CriterionContext;
export { SearchRegexParser, QueryContext, CriterionContext, OperatorContext };
SearchRegexParser.OperatorContext = OperatorContext;
