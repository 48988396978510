import React from 'react';

export type LineageEntityType = 'RUN' | 'EXPERIMENT';
export type LineageEntityTypeContextValue = {
  getLineageEntityType(projectIdentifier: string, runId: string): LineageEntityType;
};

export const LineageEntityTypeContext = React.createContext<LineageEntityTypeContextValue>({
  getLineageEntityType: () => {
    throw new Error('LineageEntityTypeContext is not provided');
  },
});

export const useLineageEntityTypeContext = (): LineageEntityTypeContextValue => {
  return React.useContext(LineageEntityTypeContext);
};
