import React from 'react';

import { AutoResizingTitle, SkeletonLoader } from '@neptune/shared/common-ui';
import { bemBlock, Header, Icon, Layout } from '@neptune/shared/venus-ui';

import './ProjectHeader.less';

type ProjectHeaderProps = {
  tabs: React.ReactNode;
  menu: React.ReactNode;
  onBackToCompare: () => void;
  dashboardMode: 'single' | 'compare';
  singleRunHeaderName?: string;
  singleRunEmblem?: React.ReactNode;
  runIdentificationKey?: string;
};

const block = bemBlock('project-header');

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  onBackToCompare,
  tabs,
  menu,
  singleRunHeaderName,
  singleRunEmblem,
  dashboardMode,
  runIdentificationKey,
}) => {
  return (
    <Header justifyContent="center" data-role="project-header" className={block()}>
      <Layout.Row alignItems="start" justifyContent="space-between">
        <Layout.Row alignItems="start" spacedChildren="sm" overflow="hidden">
          {dashboardMode === 'single' ? (
            <>
              <Icon
                className={block('back-icon')}
                onClick={onBackToCompare}
                size="lg"
                data-role="close-run-details"
                glyph="arrow-left"
              />
              {singleRunHeaderName !== undefined ? (
                <AutoResizingTitle
                  data-role="selected-run-name"
                  data-id={runIdentificationKey}
                  title={singleRunHeaderName}
                />
              ) : (
                <SkeletonLoader className={block('skeleton-loader')} />
              )}
            </>
          ) : (
            <Header.Title>Runs</Header.Title>
          )}
        </Layout.Row>
        {menu}
      </Layout.Row>
      <Layout.Row alignItems="center" spacedChildren="xl" justifyContent="space-between">
        <Layout.Row spacedChildren="sm" alignItems="center">
          {tabs}
          {singleRunEmblem}
        </Layout.Row>
      </Layout.Row>
    </Header>
  );
};
