import { getSmoothingV2Enabled } from 'common/featureFlag';

import { DataPoint } from './data-point-related';
import { SeriesViewPoint, SimpleSeriesView } from './simple-series-related';

export const converterPointFromApiToDomain = getSmoothingV2Enabled()
  ? newConverterPointFromApiToDomain
  : legacyConverterPointFromApiToDomain;

function newConverterPointFromApiToDomain(point: SeriesViewPoint): DataPoint {
  const mean =
    point.bucket && point.bucket.count ? point.bucket.sum / point.bucket.count : undefined;
  const y = mean ?? point.y ?? NaN;
  const minY = point.bucket?.minY ?? y;
  const maxY = point.bucket?.maxY ?? y;

  return [
    point.x,
    [minY, y, maxY],
    point.warnings,
    undefined, // averaging data
    point.interpolation,
    point.bucket && { sum: point.bucket.sum, count: point.bucket.count },
  ];
}

function legacyConverterPointFromApiToDomain(point: SeriesViewPoint): DataPoint {
  const y = point.y ?? NaN;
  const minY = point.bucket?.minY ?? y;
  const maxY = point.bucket?.maxY ?? y;

  return [
    point.x,
    [minY, y, maxY],
    point.warnings,
    undefined, // averaging data
    point.interpolation,
    undefined, // bucket data
  ];
}

export function convertSeriesPointsFromApiToDomain({ values }: SimpleSeriesView): DataPoint[] {
  return values.map((point) => converterPointFromApiToDomain(point));
}
