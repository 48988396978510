import { SearchQueryTerm } from '../types';

export const mergeQueries = (
  queryA: SearchQueryTerm[],
  queryB: SearchQueryTerm[],
): SearchQueryTerm[] => {
  const maxLength = Math.max(queryA.length, queryB.length);

  return Array.from({ length: maxLength }, (_, index) => queryA[index] ?? queryB[index]);
};
