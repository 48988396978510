import { last } from 'lodash';

import { searchRegexLanguageParser } from '../parser';
import { SearchQueryTermType } from '../types';

import { isAndOperator, isCriterion, isRestrictiveOperator } from './utils';

type AllowedTermTypes = {
  [SearchQueryTermType.CRITERION]: boolean;
  [SearchQueryTermType.OPERATOR]: boolean;
};

type AllowedTermsParams = {
  criterion: boolean;
  operator: boolean;
};

const createAllowedTerms = ({ criterion, operator }: AllowedTermsParams): AllowedTermTypes => ({
  [SearchQueryTermType.CRITERION]: criterion,
  [SearchQueryTermType.OPERATOR]: operator,
});

export const getNextAllowedTermTypes = (
  rawQuery: string,
  cursorPosition: number,
): AllowedTermTypes => {
  const rawQueryUpToCursor = rawQuery.substring(0, cursorPosition);
  const { query: queryUpToCursor } = searchRegexLanguageParser.parse(rawQueryUpToCursor);

  const cursorContext = {
    hasPreviousSpace: rawQueryUpToCursor[cursorPosition - 1] === ' ',
    hasFinalSpace: last(rawQueryUpToCursor) === ' ',
    hasNextSpace: rawQuery[cursorPosition] === ' ',
    isAtEnd: cursorPosition === rawQuery.length,
    precedingTerm: last(queryUpToCursor),
  };

  if (queryUpToCursor.length === 0) {
    return createAllowedTerms({ criterion: true, operator: false });
  }

  if (cursorContext.isAtEnd) {
    if (isCriterion(cursorContext.precedingTerm) && !cursorContext.hasFinalSpace) {
      return createAllowedTerms({ criterion: true, operator: true });
    }

    if (isAndOperator(cursorContext.precedingTerm)) {
      return createAllowedTerms({ criterion: true, operator: true });
    }

    if (isRestrictiveOperator(cursorContext.precedingTerm)) {
      return createAllowedTerms({ criterion: true, operator: false });
    }

    return createAllowedTerms({ criterion: false, operator: true });
  }

  if (isCriterion(cursorContext.precedingTerm)) {
    if (cursorContext.hasPreviousSpace) {
      return createAllowedTerms({ criterion: true, operator: true });
    }

    if (!cursorContext.hasNextSpace) {
      return createAllowedTerms({ criterion: true, operator: false });
    }
  }

  return createAllowedTerms({ criterion: true, operator: true });
};
