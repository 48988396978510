import React from 'react';
import { useDispatch } from 'react-redux';

import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';

import {
  performArchiveEntity,
  performBulkArchiveEntities,
  performBulkUnarchiveEntities,
  performUnarchiveEntity,
} from './archive-entity';

const TYPES = ['run'];

export function useArchiveEntity() {
  const dispatch = useDispatch();
  const { projectIdentifier } = React.useContext(LeaderboardContext);

  const bulkArchiveEntities = React.useCallback(
    async (shortIds: string[]) => {
      return performBulkArchiveEntities(projectIdentifier, TYPES, shortIds, dispatch);
    },
    [dispatch, projectIdentifier],
  );

  const bulkUnarchiveEntities = React.useCallback(
    async (shortIds: string[]) => {
      return performBulkUnarchiveEntities(projectIdentifier, TYPES, shortIds, dispatch);
    },
    [dispatch, projectIdentifier],
  );

  const archiveEntity = React.useCallback(
    async (shortId: string) => {
      return performArchiveEntity(projectIdentifier, TYPES, shortId, dispatch);
    },
    [dispatch, projectIdentifier],
  );

  const unarchiveEntity = React.useCallback(
    async (shortId: string) => {
      return performUnarchiveEntity(projectIdentifier, TYPES, shortId, dispatch);
    },
    [dispatch, projectIdentifier],
  );

  return { bulkArchiveEntities, bulkUnarchiveEntities, archiveEntity, unarchiveEntity };
}
