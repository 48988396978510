import { AnyAction } from 'redux';

import {
  leaderboardClient,
  ValidateExperimentsClipboardRequest,
} from '@neptune/shared/core-apis-leaderboard-domain';

import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { AppState, NThunkDispatch } from 'state/types';
import { showGenericModal } from 'state/ui/modals/generic/actions';

import { getSelectionState } from './selectors';
import { SelectionActionTypes } from './types';

export const SELECTION_LIMIT = 1000;
export const SELECTION_LIMIT_MODAL = 'SELECTION_LIMIT_MODAL';
export const WRONG_LEADERBOARD_MODAL = 'WRONG_LEADERBOARD_MODAL';

export function requestSelectingEntries(entries: string[], leaderboardId: string) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const selectionState = getSelectionState(getState());
    const { entries: storedEntries, leaderboardId: storedMode } = selectionState;
    const selectedEntriesCount = entries.length;

    if (typeof storedMode !== 'undefined' && storedMode !== leaderboardId) {
      dispatch(showGenericModal(WRONG_LEADERBOARD_MODAL));
      return;
    }

    if (selectedEntriesCount + storedEntries.length > SELECTION_LIMIT) {
      dispatch(showGenericModal(SELECTION_LIMIT_MODAL));
      return;
    }

    dispatch(selectLeaderboardEntries(entries, leaderboardId));
  };
}

export function selectLeaderboardEntries(entries: string[], leaderboardId: string) {
  return { type: SelectionActionTypes.Select, payload: { entries, leaderboardId } } as const;
}

export function deselectLeaderboardEntries(entries: string[]) {
  return { type: SelectionActionTypes.Deselect, payload: { entries } } as const;
}

export function deselectAllLeaderboardEntries() {
  return { type: SelectionActionTypes.DeselectAll } as const;
}

export enum SyncSelectionActionTypes {
  request = 'SYNC_SELECTION_REQUEST',
  success = 'SYNC_SELECTION_SUCCESS',
  fail = 'SYNC_SELECTION_FAIL',
}

const syncSelectionActions = createAsyncActions({
  types: SyncSelectionActionTypes,
  resolver: async (
    requestParameters: { requestId: string } & ValidateExperimentsClipboardRequest,
  ) => {
    const { requestId, ...syncParams } = requestParameters;

    const response = await leaderboardClient.validateExperimentsClipboard(syncParams);
    return {
      response,
      requestId,
    };
  },
});

export const { execute: syncSelection } = syncSelectionActions;

export type SelectionActions =
  | ReturnType<
      | typeof selectLeaderboardEntries
      | typeof deselectAllLeaderboardEntries
      | typeof deselectLeaderboardEntries
    >
  | AsyncActionsReturnType<typeof syncSelectionActions>;
