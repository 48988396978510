// Generated from SearchRegexLexer.g4 by ANTLR 4.8
// jshint ignore: start
import antlr4 from 'antlr4/index';



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u000bd\b\u0001\b\u0001\b\u0001\b\u0001\u0004\u0002\t\u0002\u0004",
    "\u0003\t\u0003\u0004\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t",
    "\u0006\u0004\u0007\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0003",
    "\u0002\u0006\u0002\u001a\n\u0002\r\u0002\u000e\u0002\u001b\u0003\u0002",
    "\u0003\u0002\u0003\u0003\u0006\u0003!\n\u0003\r\u0003\u000e\u0003\"",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0004\u0006\u0004",
    "*\n\u0004\r\u0004\u000e\u0004+\u0003\u0004\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0003\u0005\u0003\u0005\u0007\u00054\n\u0005\f\u0005\u000e\u0005",
    "7\u000b\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0007",
    "\u0006=\n\u0006\f\u0006\u000e\u0006@\u000b\u0006\u0003\u0007\u0007\u0007",
    "C\n\u0007\f\u0007\u000e\u0007F\u000b\u0007\u0003\u0007\u0003\u0007\u0003",
    "\b\u0003\b\u0007\bL\n\b\f\b\u000e\bO\u000b\b\u0003\b\u0003\b\u0003\t",
    "\u0003\t\u0007\tU\n\t\f\t\u000e\tX\u000b\t\u0003\t\u0003\t\u0003\n\u0007",
    "\n]\n\n\f\n\u000e\n`\u000b\n\u0003\n\u0003\n\u0003\n\u0002\u0002\u000b",
    "\u0006\u0003\b\u0004\n\u0005\f\u0006\u000e\u0007\u0010\b\u0012\t\u0014",
    "\n\u0016\u000b\u0006\u0002\u0003\u0004\u0005\u0004\u0005\u0002\u000b",
    "\f\u000f\u000f\"\"\u0005\u0002\u000b\f\u000f\u000f\"#\u0002i\u0002\u0006",
    "\u0003\u0002\u0002\u0002\u0002\b\u0003\u0002\u0002\u0002\u0002\n\u0003",
    "\u0002\u0002\u0002\u0002\f\u0003\u0002\u0002\u0002\u0002\u000e\u0003",
    "\u0002\u0002\u0002\u0003\u0010\u0003\u0002\u0002\u0002\u0004\u0012\u0003",
    "\u0002\u0002\u0002\u0004\u0014\u0003\u0002\u0002\u0002\u0005\u0016\u0003",
    "\u0002\u0002\u0002\u0006\u0019\u0003\u0002\u0002\u0002\b \u0003\u0002",
    "\u0002\u0002\n)\u0003\u0002\u0002\u0002\f1\u0003\u0002\u0002\u0002\u000e",
    ":\u0003\u0002\u0002\u0002\u0010D\u0003\u0002\u0002\u0002\u0012I\u0003",
    "\u0002\u0002\u0002\u0014R\u0003\u0002\u0002\u0002\u0016^\u0003\u0002",
    "\u0002\u0002\u0018\u001a\t\u0002\u0002\u0002\u0019\u0018\u0003\u0002",
    "\u0002\u0002\u001a\u001b\u0003\u0002\u0002\u0002\u001b\u0019\u0003\u0002",
    "\u0002\u0002\u001b\u001c\u0003\u0002\u0002\u0002\u001c\u001d\u0003\u0002",
    "\u0002\u0002\u001d\u001e\b\u0002\u0002\u0002\u001e\u0007\u0003\u0002",
    "\u0002\u0002\u001f!\t\u0002\u0002\u0002 \u001f\u0003\u0002\u0002\u0002",
    "!\"\u0003\u0002\u0002\u0002\" \u0003\u0002\u0002\u0002\"#\u0003\u0002",
    "\u0002\u0002#$\u0003\u0002\u0002\u0002$%\u0007(\u0002\u0002%&\u0003",
    "\u0002\u0002\u0002&\'\b\u0003\u0003\u0002\'\t\u0003\u0002\u0002\u0002",
    "(*\t\u0002\u0002\u0002)(\u0003\u0002\u0002\u0002*+\u0003\u0002\u0002",
    "\u0002+)\u0003\u0002\u0002\u0002+,\u0003\u0002\u0002\u0002,-\u0003\u0002",
    "\u0002\u0002-.\u0007~\u0002\u0002./\u0003\u0002\u0002\u0002/0\b\u0004",
    "\u0003\u00020\u000b\u0003\u0002\u0002\u000215\u0007#\u0002\u000224\t",
    "\u0002\u0002\u000232\u0003\u0002\u0002\u000247\u0003\u0002\u0002\u0002",
    "53\u0003\u0002\u0002\u000256\u0003\u0002\u0002\u000268\u0003\u0002\u0002",
    "\u000275\u0003\u0002\u0002\u000289\b\u0005\u0004\u00029\r\u0003\u0002",
    "\u0002\u0002:>\n\u0003\u0002\u0002;=\n\u0002\u0002\u0002<;\u0003\u0002",
    "\u0002\u0002=@\u0003\u0002\u0002\u0002><\u0003\u0002\u0002\u0002>?\u0003",
    "\u0002\u0002\u0002?\u000f\u0003\u0002\u0002\u0002@>\u0003\u0002\u0002",
    "\u0002AC\n\u0002\u0002\u0002BA\u0003\u0002\u0002\u0002CF\u0003\u0002",
    "\u0002\u0002DB\u0003\u0002\u0002\u0002DE\u0003\u0002\u0002\u0002EG\u0003",
    "\u0002\u0002\u0002FD\u0003\u0002\u0002\u0002GH\b\u0007\u0005\u0002H",
    "\u0011\u0003\u0002\u0002\u0002IM\u0007#\u0002\u0002JL\t\u0002\u0002",
    "\u0002KJ\u0003\u0002\u0002\u0002LO\u0003\u0002\u0002\u0002MK\u0003\u0002",
    "\u0002\u0002MN\u0003\u0002\u0002\u0002NP\u0003\u0002\u0002\u0002OM\u0003",
    "\u0002\u0002\u0002PQ\b\b\u0006\u0002Q\u0013\u0003\u0002\u0002\u0002",
    "RV\n\u0003\u0002\u0002SU\n\u0002\u0002\u0002TS\u0003\u0002\u0002\u0002",
    "UX\u0003\u0002\u0002\u0002VT\u0003\u0002\u0002\u0002VW\u0003\u0002\u0002",
    "\u0002WY\u0003\u0002\u0002\u0002XV\u0003\u0002\u0002\u0002YZ\b\t\u0005",
    "\u0002Z\u0015\u0003\u0002\u0002\u0002[]\n\u0002\u0002\u0002\\[\u0003",
    "\u0002\u0002\u0002]`\u0003\u0002\u0002\u0002^\\\u0003\u0002\u0002\u0002",
    "^_\u0003\u0002\u0002\u0002_a\u0003\u0002\u0002\u0002`^\u0003\u0002\u0002",
    "\u0002ab\b\n\u0005\u0002bc\b\n\u0005\u0002c\u0017\u0003\u0002\u0002",
    "\u0002\u000f\u0002\u0003\u0004\u0005\u001b\"+5>DMV^\u0007\b\u0002\u0002",
    "\u0007\u0004\u0002\u0007\u0003\u0002\u0006\u0002\u0002\u0007\u0005\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function SearchRegexLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

SearchRegexLexer.prototype = Object.create(antlr4.Lexer.prototype);
SearchRegexLexer.prototype.constructor = SearchRegexLexer;

Object.defineProperty(SearchRegexLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

SearchRegexLexer.EOF = antlr4.Token.EOF;
SearchRegexLexer.WS = 1;
SearchRegexLexer.AND = 2;
SearchRegexLexer.OR = 3;
SearchRegexLexer.NOT = 4;
SearchRegexLexer.CRITERION = 5;
SearchRegexLexer.NEGATED_CRITERION = 6;
SearchRegexLexer.NOT_AFTER_OPERATOR = 7;
SearchRegexLexer.CRITERION_AFTER_OPERATOR = 8;
SearchRegexLexer.NEGATED_CRITERION_AFTER_OPERATOR = 9;

SearchRegexLexer.NEGATION_MODE = 1;
SearchRegexLexer.CRITERION_MODE = 2;
SearchRegexLexer.NEGATION_AFTER_OPERATOR_MODE = 3;

SearchRegexLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

SearchRegexLexer.prototype.modeNames = [ "DEFAULT_MODE", "NEGATION_MODE", 
                                         "CRITERION_MODE", "NEGATION_AFTER_OPERATOR_MODE" ];

SearchRegexLexer.prototype.literalNames = [  ];

SearchRegexLexer.prototype.symbolicNames = [ null, "WS", "AND", "OR", "NOT", 
                                             "CRITERION", "NEGATED_CRITERION", 
                                             "NOT_AFTER_OPERATOR", "CRITERION_AFTER_OPERATOR", 
                                             "NEGATED_CRITERION_AFTER_OPERATOR" ];

SearchRegexLexer.prototype.ruleNames = [ "WS", "AND", "OR", "NOT", "CRITERION", 
                                         "NEGATED_CRITERION", "NOT_AFTER_OPERATOR", 
                                         "CRITERION_AFTER_OPERATOR", "NEGATED_CRITERION_AFTER_OPERATOR" ];

SearchRegexLexer.prototype.grammarFileName = "SearchRegexLexer.g4";


export { SearchRegexLexer };

