import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';
import {
  GroupByContext,
  GroupByField,
  GroupByStorage,
  LeaderboardListGroupKey,
} from '@neptune/shared/leaderboard-domain';

import {
  closeGroupPreview as closeGroupPreviewRaw,
  openGroupPreview as openGroupPreviewRaw,
} from 'state/project-leaderboard/groups-preview/actions';
import type { FetchStatusMap } from 'state/project-leaderboard/groups-preview/reducer';
import {
  getFetchStatusMap,
  getOpenedGroups,
} from 'state/project-leaderboard/groups-preview/selector';
import type { AppState } from 'state/types';

import { fetchGroupCompareStatus } from './redux/group-compare-actions';
import { getRunIdsInGroup, getSelectedGroupCount } from './redux/group-compare-selectors';
import { useExperimentsOnlyOptions } from './use-experiments-only-options';
import { useSortOptions } from './use-sort-options';

type UseGroupByReturnValue = {
  fetchStatusMap: FetchStatusMap;
  isEnabled: boolean;
  options: GroupByStorage | undefined;
  setOptions: (options: GroupByStorage) => void;
  openGroupPreview: (
    groupId: string,
    groupKey: LeaderboardListGroupKey[],
    paginationTarget?: { beforeToken: string } | { continuationToken: string },
  ) => void;
  closeGroupPreview: (groupId: string) => void;
  openedGroups: {
    openedGroup: string;
    paginationTarget?: { beforeToken: string } | { continuationToken: string };
  }[];
  isGroupByAvailable: boolean;
  updateGroupCompare: (
    groupBy: GroupByField[],
    selectedIds: string[],
    query?: string,
    openedGroupIds?: string[],
  ) => void;
  selectedGroupCount: Record<string, number | undefined>;
  runIdsInGroup: Record<string, string[]>;
};

export const useGroupBy = (): UseGroupByReturnValue => {
  const dispatch = useDispatch();
  const { isGroupByAvailable, storageStrategy } = React.useContext(GroupByContext);

  const {
    id: leaderboardId,
    projectIdentifier,
    entityType,
    entityId,
    types,
  } = React.useContext(LeaderboardContext);
  const [experimentsOnlyEnabled] = useExperimentsOnlyOptions();

  const { selector, currentQuerySelector, actionCreator } = storageStrategy;
  const [sortOptions] = useSortOptions();

  const options = useSelector(selector);

  const setOptions = React.useCallback(
    (options: GroupByStorage) => {
      dispatch(actionCreator(options));
    },
    [dispatch, actionCreator],
  );

  const fetchStatusMap = useSelector((state: AppState) => getFetchStatusMap(state, leaderboardId));

  const openedGroups = useSelector((state: AppState) => getOpenedGroups(state, leaderboardId));

  const isEnabled = !!options?.groupBy?.length;
  const currentQuery = useSelector(currentQuerySelector);

  const selectedGroupCount = useSelector((state: AppState) =>
    getSelectedGroupCount(state, leaderboardId),
  );

  const runIdsInGroup = useSelector((state: AppState) => getRunIdsInGroup(state, leaderboardId));

  const closeGroupPreview = React.useCallback(
    (groupId: string) => {
      dispatch(closeGroupPreviewRaw({ leaderboardIdentifier: leaderboardId, groupId }));
    },
    [dispatch, leaderboardId],
  );

  const openGroupPreview = React.useCallback(
    (
      groupId: string,
      groupKey: LeaderboardListGroupKey[],
      paginationTarget?: { beforeToken: string } | { continuationToken: string },
    ) => {
      dispatch(
        openGroupPreviewRaw({
          currentQuery,
          leaderboardIdentifier: leaderboardId,
          groupId,
          groupKey,
          paginationTarget,
          sortOptions,
          entityId,
          entityType,
          types,
          experimentsOnly: Boolean(experimentsOnlyEnabled),
        }),
      );
    },
    [
      dispatch,
      currentQuery,
      leaderboardId,
      sortOptions,
      entityId,
      entityType,
      types,
      experimentsOnlyEnabled,
    ],
  );

  const updateGroupCompare = React.useCallback(
    (groupBy: GroupByField[], selectedIds: string[], query?: string, openedGroupIds?: string[]) => {
      dispatch(
        fetchGroupCompareStatus({
          projectIdentifier,
          groupBy,
          selectedIds,
          type: types,
          query,
          leaderboardIdentifier: leaderboardId,
          openedGroupIds,
          experimentsOnly: experimentsOnlyEnabled,
        }),
      );
    },
    [dispatch, experimentsOnlyEnabled, leaderboardId, projectIdentifier, types],
  );

  return {
    openGroupPreview,
    closeGroupPreview,
    updateGroupCompare,
    selectedGroupCount,
    fetchStatusMap,
    isEnabled,
    options,
    setOptions,
    openedGroups,
    isGroupByAvailable,
    runIdsInGroup,
  };
};
