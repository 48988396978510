import { useSelector } from 'react-redux';

import { getCurrentBasicEntity } from '@neptune/current-entity-business-logic';
import { getEntityArchived, getEntityName } from '@neptune/shared/entity-domain';
import { getEntityDisplayName } from '@neptune/shared/entity-util';
import { createCurrentRouteParamSelector } from '@neptune/shared/routing-business-logic';

import { getIsExperimentHeadFromAttributes } from 'domain/experiment/attribute';
import { AppState } from 'state/types';

import config from 'config';

const runIdentificationKeySelector = createCurrentRouteParamSelector('runIdentificationKey');

const getCurrentRunName = (state: AppState): string | undefined => {
  const runIdentificationKey = runIdentificationKeySelector(state);

  if (config.primaryColumn === 'id') {
    return runIdentificationKey;
  }

  const currentBasicEntity = getCurrentBasicEntity(state);

  if (!currentBasicEntity) {
    return undefined;
  }

  return getEntityDisplayName(runIdentificationKey, getEntityName(currentBasicEntity));
};

export const useCurrentRunIdentity = (): {
  currentRunName: string | undefined;
  currentRunIdentificationKey: string | undefined;
  isExperimentHead: boolean | undefined;
  isArchived: boolean | null;
} => {
  const currentBasicEntity = useSelector(getCurrentBasicEntity);
  const currentRunName = useSelector(getCurrentRunName);
  const currentRunIdentificationKey = useSelector(runIdentificationKeySelector);
  const isExperimentHead = currentBasicEntity
    ? getIsExperimentHeadFromAttributes(currentBasicEntity.attributes)
    : undefined;
  const isArchived = currentBasicEntity ? getEntityArchived(currentBasicEntity) : null;

  return {
    currentRunName,
    currentRunIdentificationKey,
    isExperimentHead,
    isArchived,
  };
};
