// App
import { ArrayElementType } from 'common/utility-types';
import { availableAttributeTypes } from 'domain/experiment/attribute';

// Module
import { Reorderable } from './types';

export const availableColumnTypes = [...availableAttributeTypes] as const;
export type ColumnType = ArrayElementType<typeof availableAttributeTypes>;

export type ColumnIdentifier = {
  id: string;
  columnType: ColumnType;

  // For now we only have subproperties for FloatSeries column.
  // If we will have more, then we should implement subproperties in more generic way
  aggregationMode?: AggregationMode;
};

export type AggregationMode = 'last' | 'min' | 'max' | 'average' | 'variance' | 'auto';

export type DisplayMode = 'scientific' | 'decimal' | 'auto';

export type BaseColumn = ColumnIdentifier & {
  attributeName?: string;
  pinned: boolean;
  width: number;
  displayName?: string;
  color?: string;
  exportable: boolean;
  groupable: boolean;
  hideable: boolean;
  reorderable: Reorderable;
  resizable: boolean;
  sortable: boolean;
  autoWidthEnabled?: boolean;
};

export type FloatColumn = BaseColumn & {
  columnType: 'float';
  displayMode: DisplayMode;
};

export type FloatSeriesColumn = BaseColumn & {
  columnType: 'floatSeries';
  aggregationMode: AggregationMode;
  displayMode: DisplayMode;
};

export type LeaderboardColumn = BaseColumn | FloatColumn | FloatSeriesColumn;

export type LeaderboardColumnCreationParams = ColumnIdentifier & {
  attributeName?: string;
  pinned?: boolean;
  width?: number;
  displayName?: string;
  color?: string;
  displayMode?: DisplayMode;
  aggregationMode?: AggregationMode;
};
