import { type Dispatch } from 'redux';

import { plural } from '@neptune/shared/common-util';
import { archiveEntities, getEntityUuids, unarchiveEntities } from '@neptune/shared/entity-domain';
import { makeEntityUuidIdentifier } from '@neptune/shared/entity-util';

import { createSelectionQuery } from 'common/query';
import { addNotification } from 'state/ui/global/notification-popup/actions';
import { deselectAllLeaderboardEntries } from 'state/ui/leaderboards/selection/actions';
import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

function getNotificationText(action: 'archive' | 'unarchive', amount: number): string {
  const runs = plural(amount, { one: 'Run', other: 'Runs' });
  const actionText = action === 'archive' ? 'archive' : 'restore';
  return `${runs} ${actionText}d successfully, this action does not take effect immediately, please refresh the page in a short while to see the changes.`;
}

async function performArchiveAction(
  projectIdentifier: string,
  types: string[],
  action: 'archive' | 'unarchive',
  isBulk: boolean,
  shortIds: string[],
  dispatch: Dispatch,
) {
  const entries = await getEntityUuids(
    projectIdentifier,
    types,
    createSelectionQuery(shortIds),
    shortIds.length,
  );
  const successText = getNotificationText(action, shortIds.length);

  try {
    const actionFn = action === 'archive' ? archiveEntities : unarchiveEntities;
    await actionFn(
      entries.map((entry) =>
        makeEntityUuidIdentifier(entry.organizationName, entry.projectName, entry.id),
      ),
    );
    dispatch(addNotification('success', successText));

    if (isBulk) {
      dispatch(deselectAllLeaderboardEntries());
    }
  } catch {
    showServerErrorModal();
  }
}

export function performBulkArchiveEntities(
  projectIdentifier: string,
  types: string[],
  shortIds: string[],
  dispatch: Dispatch,
) {
  return performArchiveAction(projectIdentifier, types, 'archive', true, shortIds, dispatch);
}

export function performBulkUnarchiveEntities(
  projectIdentifier: string,
  types: string[],
  shortIds: string[],
  dispatch: Dispatch,
) {
  return performArchiveAction(projectIdentifier, types, 'unarchive', true, shortIds, dispatch);
}

export function performArchiveEntity(
  projectIdentifier: string,
  types: string[],
  shortId: string,
  dispatch: Dispatch,
) {
  return performArchiveAction(projectIdentifier, types, 'archive', false, [shortId], dispatch);
}

export function performUnarchiveEntity(
  projectIdentifier: string,
  types: string[],
  shortId: string,
  dispatch: Dispatch,
) {
  return performArchiveAction(projectIdentifier, types, 'unarchive', false, [shortId], dispatch);
}
