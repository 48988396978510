import { identity, sampleSize } from 'lodash';

import {
  COLOR_CLUSTERS,
  COLOR_MAP,
  COLOR_PALETTE,
  ColorTuple,
  HEXColor,
} from '@neptune/colors-domain';

import { shuffleWithHash } from 'common/array';

export function getColorByIndex(index: number): HEXColor {
  return COLOR_PALETTE[index % COLOR_PALETTE.length][0];
}

export function countUsedColors(colors: Map<string, string>) {
  const usedColorsCount = new Map<ColorTuple, number>(COLOR_PALETTE.map((color) => [color, 0]));

  for (const hex of colors.values()) {
    const colorTuple = COLOR_MAP[hex as HEXColor];

    if (colorTuple) {
      usedColorsCount.set(colorTuple, (usedColorsCount.get(colorTuple) || 0) + 1);
    }
  }

  return usedColorsCount;
}

export function getNextColor(
  usedColorsCounted: Map<ColorTuple, number>,
  shuffle: (array: Set<ColorTuple>[]) => Set<ColorTuple>[] = identity,
): ColorTuple {
  const usedColors = new Set<ColorTuple>();
  const usedHEXValues = new Set<string>();
  const minCount = Math.min(...usedColorsCounted.values());

  for (const [color, count] of usedColorsCounted) {
    if (count > minCount) {
      usedColors.add(color);
      usedHEXValues.add(color[0]);
    }
  }

  const clusterPriorities = new Map<Set<ColorTuple>, number>(
    shuffle(COLOR_CLUSTERS).map((cluster) => [cluster, 1]),
  );

  for (const color of usedColors) {
    for (const cluster of COLOR_CLUSTERS) {
      if (cluster.has(color)) {
        const contribution = 1 / cluster.size;

        clusterPriorities.set(cluster, (clusterPriorities.get(cluster) || 1) - contribution);
      }
    }
  }

  let highestPriority = 0;
  let cluster = COLOR_CLUSTERS[0];

  for (const [colors, priority] of clusterPriorities.entries()) {
    if (priority > highestPriority) {
      highestPriority = priority;
      cluster = colors;
    }
  }

  if (highestPriority === 1) {
    return [...cluster][0];
  }

  return sampleSize(
    [...cluster].filter((color) => !usedHEXValues.has(color[0])),
    1,
  )[0];
}

export function assignColorsToKeys(
  keys: string[],
  usedColorsCounted: Map<ColorTuple, number>,
): Map<string, string> {
  const newColors = new Map();

  if (keys.length === 0) {
    return newColors;
  }

  const shuffle = shuffleWithHash<Set<ColorTuple>>(keys[0]);

  for (const colorKey of keys) {
    const color = getNextColor(usedColorsCounted, shuffle);

    usedColorsCounted.set(color, (usedColorsCounted.get(color) || 0) + 1);

    newColors.set(colorKey, color[0]);
  }

  return newColors;
}

export function hashText(name: string) {
  let hashValue = 0;

  for (let i = 0; i < name.length; i++) {
    hashValue = name.charCodeAt(i) * (i + 1);
  }

  return hashValue;
}

export function getColorByText(text: string): string {
  const index = Math.abs(hashText(text)) % COLOR_PALETTE.length;

  return COLOR_PALETTE[index][0];
}
