import { ParseTreeListener, TerminalNode } from 'antlr4/tree/Tree';

import { isValidRegExp } from '@neptune/shared/common-util';

import { SearchQueryTerm, SearchQueryTermType } from '../types';

import {
  SearchRegexQueryParserErrorCode,
  SearchRegexQueryParserNodeSymbol,
  SearchRegexQueryParserResult,
} from './types';

export class SearchRegexQueryParserListener
  implements ParseTreeListener, SearchRegexQueryParserResult
{
  constructor(private symbolicNames: string[]) {}

  status: 'valid' | 'invalid' = 'valid';
  query: SearchQueryTerm[] = [];
  errorCode?: SearchRegexQueryParserErrorCode;

  visitTerminal(node: TerminalNode): void {
    if (!this.shouldParseNode(node)) {
      return;
    }

    const type = this.getNodeType(node);
    const value = this.getNodeValue(node);

    if (!isValidRegExp(value)) {
      this.status = 'invalid';
      this.errorCode = SearchRegexQueryParserErrorCode.QUERY_CONTAINS_INVALID_REGEXP;
      return;
    }

    const newQueryTerm: SearchQueryTerm = {
      type,
      value,
    };

    if (this.isNotOperatorTerm(newQueryTerm)) {
      this.handleNotOperator();
      return;
    }

    this.query.push(newQueryTerm);
  }

  private handleNotOperator(): void {
    const lastQueryTerm = this.getLastQueryTerm();

    if (!lastQueryTerm) {
      this.status = 'invalid';
      this.errorCode = SearchRegexQueryParserErrorCode.NOT_OPERATOR_NOT_ALLOWED_AT_THE_BEGINNING;
      return;
    }

    this.query.pop();

    if (this.isAndOperatorTerm(lastQueryTerm)) {
      this.query.push({
        type: SearchQueryTermType.OPERATOR,
        value: 'AND NOT',
      });
      return;
    }

    if (this.isOrOperatorTerm(lastQueryTerm)) {
      this.query.push({
        type: SearchQueryTermType.OPERATOR,
        value: 'OR NOT',
      });
      this.status = 'invalid';
      this.errorCode = SearchRegexQueryParserErrorCode.OR_NOT_OPERATOR_NOT_ALLOWED;
      return;
    }
  }

  visitErrorNode(): void {
    this.status = 'invalid';
    this.errorCode = SearchRegexQueryParserErrorCode.MALFORMED_QUERY;
  }

  enterEveryRule(): void {
    void 0;
  }

  exitEveryRule(): void {
    void 0;
  }

  private isNotOperatorTerm(term: SearchQueryTerm): boolean {
    return term.type === SearchQueryTermType.OPERATOR && term.value === 'NOT';
  }

  private isAndOperatorTerm(term: SearchQueryTerm): boolean {
    return term.type === SearchQueryTermType.OPERATOR && term.value === 'AND';
  }

  private isOrOperatorTerm(term: SearchQueryTerm): boolean {
    return term.type === SearchQueryTermType.OPERATOR && term.value === 'OR';
  }

  private getLastQueryTerm(): SearchQueryTerm | null {
    return this.query[this.query.length - 1] ?? null;
  }

  private getNodeType(node: TerminalNode): SearchQueryTermType {
    const symbol = this.getNodeSymbol(node);

    switch (symbol) {
      case SearchRegexQueryParserNodeSymbol.AND:
      case SearchRegexQueryParserNodeSymbol.OR:
      case SearchRegexQueryParserNodeSymbol.NOT:
      case SearchRegexQueryParserNodeSymbol.NOT_AFTER_OPERATOR:
        return SearchQueryTermType.OPERATOR;
      case SearchRegexQueryParserNodeSymbol.CRITERION:
      case SearchRegexQueryParserNodeSymbol.NEGATED_CRITERION:
      case SearchRegexQueryParserNodeSymbol.CRITERION_AFTER_OPERATOR:
      case SearchRegexQueryParserNodeSymbol.NEGATED_CRITERION_AFTER_OPERATOR:
      default:
        return SearchQueryTermType.CRITERION;
    }
  }

  private getNodeValue(node: TerminalNode): string {
    const symbol = this.getNodeSymbol(node);

    switch (symbol) {
      case SearchRegexQueryParserNodeSymbol.AND:
        return 'AND';
      case SearchRegexQueryParserNodeSymbol.OR:
        return 'OR';
      case SearchRegexQueryParserNodeSymbol.NOT:
      case SearchRegexQueryParserNodeSymbol.NOT_AFTER_OPERATOR:
        return 'NOT';
      case SearchRegexQueryParserNodeSymbol.CRITERION:
      case SearchRegexQueryParserNodeSymbol.NEGATED_CRITERION:
      case SearchRegexQueryParserNodeSymbol.CRITERION_AFTER_OPERATOR:
      case SearchRegexQueryParserNodeSymbol.NEGATED_CRITERION_AFTER_OPERATOR:
      default:
        return this.unescapeSpecialCharacters(node.getText());
    }
  }

  private unescapeSpecialCharacters(text: string): string {
    return text
      .replace(/(?<!\\)\\x20/g, ' ')
      .replace(/\\\\x20/g, '\\x20')
      .replace(/^\\!/g, '!');
  }

  private getNodeSymbol(node: TerminalNode): SearchRegexQueryParserNodeSymbol {
    const symbolIndex = node.getSymbol().type;

    if (symbolIndex === -1) {
      return SearchRegexQueryParserNodeSymbol.EOF;
    }

    return this.symbolicNames[symbolIndex] as SearchRegexQueryParserNodeSymbol;
  }

  private shouldParseNode(node: TerminalNode): boolean {
    return (
      this.status === 'valid' && this.getNodeSymbol(node) !== SearchRegexQueryParserNodeSymbol.EOF
    );
  }
}
