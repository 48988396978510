import React from 'react';
import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';

import { getCurrentProject } from '@neptune/current-project-business-logic';
import { useCurrentRunIdentity } from '@neptune/project-business-logic';
import {
  determineTab,
  pickTabName,
  useRunsViewMode,
  useTabNavigation,
} from '@neptune/project-runs-business-logic';
import { compareDefaultTabs } from '@neptune/project-runs-domain';
import { ProjectHeader } from '@neptune/project-ui';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';
import { Emblem, OverlayView, Text } from '@neptune/shared/venus-ui';

import { getRunsArchivingEnabled } from 'common/featureFlag';

import { ProjectDropdownMenuContainer } from './ProjectDropdownMenuContainer';
import { useOverlaySubviews } from './useOverlaySubviews';

type ProjectHeaderContentContainerProps = {
  target: HTMLElement | null;
  tabs: React.ReactNode;
};

const isArchivingEnabled = getRunsArchivingEnabled();

export const ProjectHeaderContainer: React.FC<ProjectHeaderContentContainerProps> = ({
  target,
  tabs,
}) => {
  const project = useSelector(getCurrentProject);
  const params = useSelector(getCurrentRouteParams);

  const { closeCurrentSubview, currentSubviewComponent, openSubview, getSubviewEntries } =
    useOverlaySubviews();
  const { dashboardMode } = useRunsViewMode();
  const { navigateToCompare } = useTabNavigation();

  const tabName = pickTabName(params, dashboardMode);

  const { currentRunIdentificationKey, currentRunName, isExperimentHead, isArchived } =
    useCurrentRunIdentity();

  const singleRunHeaderEmblem = React.useMemo(() => {
    if (dashboardMode != 'single') {
      return null;
    }

    if (isArchivingEnabled && isArchived) {
      return (
        <Emblem
          data-role="archived-emblem"
          text={<Text color="text-subdued" children="Archived" />}
          size="sm"
          color="#F2F2F3"
        />
      );
    }

    if (!isExperimentHead) {
      return null;
    }

    const entityTypeFromRoute: 'experiment' | 'run' | undefined = params.type;

    if (!entityTypeFromRoute) {
      return null;
    }

    const emblemText =
      entityTypeFromRoute === 'experiment' ? 'Viewing as experiment' : 'Experiment HEAD';

    return (
      <Emblem text={<Text color="text-action" children={emblemText} />} size="sm" color="#F6EFFF" />
    );
  }, [params.type, isExperimentHead, dashboardMode, isArchived]);

  const subviewEntries = React.useMemo(
    () => getSubviewEntries(project),
    [getSubviewEntries, project],
  );

  const handleBackToCompare = React.useCallback(() => {
    const compareModeFallbackTab = determineTab(compareDefaultTabs, 'table');
    const compareTab = determineTab(compareDefaultTabs, tabName) || compareModeFallbackTab;

    if (!compareTab) {
      return;
    }

    navigateToCompare(compareTab);
  }, [tabName, navigateToCompare]);

  if (!target || !project) {
    return null;
  }

  return ReactDom.createPortal(
    <>
      <ProjectHeader
        runIdentificationKey={currentRunIdentificationKey}
        menu={
          <ProjectDropdownMenuContainer
            openSubview={openSubview}
            subviewEntries={subviewEntries}
            project={project}
          />
        }
        tabs={tabs}
        onBackToCompare={handleBackToCompare}
        dashboardMode={dashboardMode}
        singleRunHeaderName={currentRunName}
        singleRunEmblem={singleRunHeaderEmblem}
      />
      <OverlayView
        children={currentSubviewComponent || null}
        onClose={closeCurrentSubview}
        isVisible={!!currentSubviewComponent}
      />
    </>,
    target,
  );
};
