import {
  BaseLeaderboardEntry,
  LeaderboardListModel,
  LeaderboardListSuggestion,
} from '@neptune/shared/leaderboard-domain';

export enum LeaderboardActionTypes {
  fetchStart = 'LEADERBOARD_FETCH_START',
  fetchSuccess = 'LEADERBOARD_FETCH_SUCCESS',
  fetchAbort = 'LEADERBOARD_FETCH_ABORT',
  fetchFail = 'LEADERBOARD_FETCH_FAIL',
  declineSuggestion = 'LEADERBOARD_DECLINE_SUGGESTION',
}

export const fetchLeaderboard = (id: string) => {
  return {
    type: LeaderboardActionTypes.fetchStart,
    id,
  } as const;
};

type FetchLeaderboardSuccessParams<T extends BaseLeaderboardEntry> = {
  data: LeaderboardListModel<T>;
  query: any;
};

export const fetchLeaderboardSuccess = <T extends BaseLeaderboardEntry>(
  id: string,
  { data, query }: FetchLeaderboardSuccessParams<T>,
) => {
  return {
    type: LeaderboardActionTypes.fetchSuccess,
    id,
    payload: {
      timestamp: Date.now(),
      entries: data.entries,
      groups: data.groups,
      suggestions: data.suggestions,
      matchingItemCount: data.matchingItemCount,
      totalGroupCount: data.totalGroupCount,
      query,
    },
  } as const;
};

export const fetchLeaderboardFail = (id: string, error: any) => {
  return {
    type: LeaderboardActionTypes.fetchFail,
    id,
    payload: {
      error,
    },
  } as const;
};

export const fetchLeaderboardAbort = (id: string) => {
  return {
    type: LeaderboardActionTypes.fetchAbort,
    id,
  } as const;
};

export const declineSuggestion = (id: string, suggestion: LeaderboardListSuggestion) => {
  return {
    type: LeaderboardActionTypes.declineSuggestion,
    id,
    payload: {
      suggestion,
      timestamp: +new Date(),
    },
  } as const;
};

type LeaderboardFetchAction = ReturnType<typeof fetchLeaderboard>;
type LeaderboardFetchSuccessAction = ReturnType<typeof fetchLeaderboardSuccess>;

export type LeaderboardsActions =
  | LeaderboardFetchAction
  | LeaderboardFetchSuccessAction
  | ReturnType<
      typeof fetchLeaderboardFail | typeof fetchLeaderboardAbort | typeof declineSuggestion
    >;
