import React from 'react';

import { useColorsPaletteContext } from '@neptune/colors-business-logic';
import { HEXColor } from '@neptune/colors-domain';
import { useStrokePatternsPaletteContext } from '@neptune/stroke-patterns-business-logic';

import { hexToRgba } from 'common/color';

type RunCompareStyles = {
  color: HEXColor | undefined;
  strokePattern?: number[];
  backgroundColor: string | undefined;
};

export function useRunStyles(entityIdentifier: string, groupTag?: string): RunCompareStyles {
  const { getColor, getGroupColor } = useColorsPaletteContext();
  const { getStrokePattern } = useStrokePatternsPaletteContext();

  return React.useMemo(() => {
    const color = getColor(entityIdentifier);
    const strokePattern = getStrokePattern?.(entityIdentifier);

    return {
      color,
      strokePattern,
      backgroundColor: groupTag ? hexToRgba(getGroupColor(groupTag), 0.08) : undefined,
    };
  }, [getColor, getGroupColor, getStrokePattern, entityIdentifier, groupTag]);
}
