import { SearchQueryTerm, SearchQueryTermType } from '../types';

export const isCriterion = (term?: SearchQueryTerm) => term?.type === SearchQueryTermType.CRITERION;

export const isAndOperator = (term?: SearchQueryTerm) =>
  term?.type === SearchQueryTermType.OPERATOR && term.value === 'AND';

export const isOrOperator = (term?: SearchQueryTerm) =>
  term?.type === SearchQueryTermType.OPERATOR && term.value === 'OR';

export const isAndNotOperator = (term?: SearchQueryTerm) =>
  term?.type === SearchQueryTermType.OPERATOR && term.value === 'AND NOT';

export const isNotOperator = (term?: SearchQueryTerm) =>
  term?.type === SearchQueryTermType.OPERATOR && term.value === 'NOT';

export const isRestrictiveOperator = (term?: SearchQueryTerm) =>
  term?.type === SearchQueryTermType.OPERATOR && (term.value === 'AND NOT' || term.value === 'OR');

export const areTermsSameType = (termA?: SearchQueryTerm, termB?: SearchQueryTerm) =>
  termA?.type === termB?.type;
