import { SearchQueryTerm } from '../types';

export enum SearchRegexQueryParserErrorCode {
  OR_NOT_OPERATOR_NOT_ALLOWED = 'OR_NOT_OPERATOR_NOT_ALLOWED',
  NOT_OPERATOR_NOT_ALLOWED_AT_THE_BEGINNING = 'NOT_OPERATOR_NOT_ALLOWED_AT_THE_BEGINNING',
  MALFORMED_QUERY = 'MALFORMED_QUERY',
  QUERY_CONTAINS_INVALID_REGEXP = 'QUERY_CONTAINS_INVALID_REGEXP',
  PARTIAL_QUERY = 'PARTIAL_QUERY',
}

export type SearchRegexQueryParserResult = {
  status?: 'valid' | 'invalid' | 'partial';
  errorCode?: SearchRegexQueryParserErrorCode;
  query: SearchQueryTerm[];
};

export enum SearchRegexQueryParserNodeSymbol {
  EOF = 'EOF',
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
  NOT_AFTER_OPERATOR = 'NOT_AFTER_OPERATOR',
  CRITERION = 'CRITERION',
  NEGATED_CRITERION = 'NEGATED_CRITERION',
  CRITERION_AFTER_OPERATOR = 'CRITERION_AFTER_OPERATOR',
  NEGATED_CRITERION_AFTER_OPERATOR = 'NEGATED_CRITERION_AFTER_OPERATOR',
}
