export const generateSearchPlaceholder = ({
  regexSupport,
  entityType,
}: {
  regexSupport: boolean;
  entityType?: string;
}): string => {
  const baseText = entityType ? `Search ${entityType}` : 'Search';
  return regexSupport ? `${baseText} with regex...` : `${baseText} by name...`;
};

export const generateShortSearchPlaceholder = (params?: { regexSupport?: boolean }) => {
  return params?.regexSupport === true ? 'Search with regex...' : 'Search...';
};
