import { RunIdentificationPair } from '@neptune/shared/entity-domain';

type DetailsTabParams = {
  tab: 'details';
  projectName: string;
  organizationName: string;
  type: 'run' | 'experiment';
  runIdentificationKey: string;
};

type BaseProps = {
  projectName: string;
  organizationName: string;
};

export function makeRunDetailsViewParams({
  projectName,
  organizationName,
  runIdentificationPair,
}: BaseProps & { runIdentificationPair?: RunIdentificationPair }): DetailsTabParams;
export function makeRunDetailsViewParams({
  projectName,
  organizationName,
  experimentName,
}: BaseProps & { experimentName: string }): DetailsTabParams;
export function makeRunDetailsViewParams({
  projectName,
  organizationName,
  runId,
}: BaseProps & { runId: string }): DetailsTabParams;

export function makeRunDetailsViewParams({
  projectName,
  organizationName,
  runId,
  experimentName,
  runIdentificationPair,
}: BaseProps & {
  runId?: string;
  experimentName?: string;
  runIdentificationPair?: RunIdentificationPair;
}): DetailsTabParams {
  if (experimentName) {
    return {
      projectName,
      tab: 'details',
      organizationName,
      type: 'experiment',
      runIdentificationKey: experimentName,
    };
  }

  if (runId) {
    return {
      projectName,
      tab: 'details',
      organizationName,
      type: 'run',
      runIdentificationKey: runId,
    };
  }

  if (runIdentificationPair) {
    return {
      projectName,
      tab: 'details',
      organizationName,
      type: runIdentificationPair?.experimentName ? 'experiment' : 'run',
      runIdentificationKey: runIdentificationPair.experimentName ?? runIdentificationPair.runId,
    };
  }

  throw new Error('Invalid params for run details view');
}
