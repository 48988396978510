import { Recognizer, Token } from 'antlr4';
import { ErrorListener } from 'antlr4/error/ErrorListener';

export class SearchRegexQueryParserSilentErrorListener implements ErrorListener {
  error: string | undefined;

  syntaxError(
    recognizer: Recognizer,
    offendingSymbol: Token,
    line: number,
    column: number,
    msg: string,
    e: any,
  ) {
    void e;

    if (!this.error) {
      this.error = msg;
    }
  }

  reportAmbiguity() {
    void 0;
  }

  reportAttemptingFullContext() {
    void 0;
  }

  reportContextSensitivity() {
    void 0;
  }
}
