import { useLeaderboardCount } from '@neptune/shared/leaderboard-business-logic';

import { buildSearchLeaderboardQuery } from './buildSearchLeaderboardQuery';

type UseEntityLeaderboardCountParams = {
  entityId: string;
  entityType: string;
  projectIdentifier: string;
  types: string[];
};

export const useEntityLeaderboardCount = ({
  entityId,
  entityType,
  projectIdentifier,
  types,
}: UseEntityLeaderboardCountParams) => {
  const query = buildSearchLeaderboardQuery(entityType, entityId);

  return useLeaderboardCount({
    types,
    projectIdentifier,
    query,
  });
};
