import { chunk } from 'lodash';

import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import { ShortIdsByProject } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

import { Attribute, AttributeModelConverter } from 'domain/experiment/attribute';

type QueryAttributesRequest = {
  limit?: number;
  fields: string[];
  nextPageToken?: string;
  /** array of **qualified id** or run **UUID** that uniquely identifies run in the entire system (cross project and workspaces)  */
  experimentIdsFilter?: string[];
};

type QueryAttributesResponse = {
  nextPageToken?: string;
  result: {
    shortId: string;
    entityId: string;
    projectName: string;
    organizationName: string;
    attributes: Attribute[];
    projectIdentifier: string;
  }[];
};

export async function queryAttributes({
  limit,
  nextPageToken,
  experimentIdsFilter,
  fields: attributeNamesFilter,
}: QueryAttributesRequest): Promise<QueryAttributesResponse> {
  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  const result = await leaderboardClient.queryAttributesWithinProject({
    query: {
      nextPage,
      experimentIdsFilter,
      attributeNamesFilter,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    result: result.entries.map(
      ({
        attributes,
        experimentShortId: shortId,
        experimentId: entityId,
        organizationName,
        projectName,
      }) => ({
        shortId,
        entityId,
        projectName,
        organizationName,
        projectIdentifier: makeEntityIdentifier(organizationName, projectName),
        attributes: attributes.map(AttributeModelConverter.attributeFromApiToDomain),
      }),
    ),
  };
}

type QueryAllAttributesRequestBase = {
  fields: string[];
};

type QueryAllAttributesRequestMultiProject = QueryAllAttributesRequestBase & {
  shortIdsByProject: ShortIdsByProject;
  projectIdentifier?: never;
  shortIds?: never;
};

type QueryAllAttributesRequest = QueryAllAttributesRequestMultiProject;

const MAX_CELL_DATA_TO_FETCH_IN_SINGLE_REQUEST = 10_000;

/**
 * Fetches all attributes for given fields and shortIds. Bare endpoint returns up to
 * `MAX_CELL_DATA_TO_FETCH_IN_SINGLE_REQUEST` (10_000) cells per request. This function handles
 * pagination and returns all cells for given fields and shortIds.
 */
export async function queryFieldsFromAllPages(
  params: QueryAllAttributesRequestMultiProject,
): Promise<QueryAttributesResponse['result']>;

export async function queryFieldsFromAllPages({
  fields,
  shortIdsByProject,
}: QueryAllAttributesRequest): Promise<QueryAttributesResponse['result']> {
  const experimentIdsFilter = Array.from(shortIdsByProject.entries()).flatMap(
    ([project, shortIds]) => shortIds.map((shortId) => makeEntityIdentifier(project, shortId)),
  );

  const shortIdChunkSize = Math.floor(MAX_CELL_DATA_TO_FETCH_IN_SINGLE_REQUEST / fields.length);
  const totalChunks = Math.ceil(experimentIdsFilter.length / shortIdChunkSize);
  const amortizedChunkSize = Math.ceil(experimentIdsFilter.length / totalChunks);

  const result = await Promise.all(
    chunk(experimentIdsFilter, amortizedChunkSize).map((chunk) =>
      queryAttributes({ fields, experimentIdsFilter: chunk }),
    ),
  );

  return result.flatMap(({ result: pageResult }) => pageResult);
}

/**
 * Fetches all attributes for given fields and uuids. Bare endpoint returns up to
 * `MAX_CELL_DATA_TO_FETCH_IN_SINGLE_REQUEST` (10_000) cells per request. This function handles
 * pagination and returns all cells for given fields and shortIds.
 */
export async function queryFieldsFromAllPagesByUuid({
  fields,
  uuids,
}: QueryAllAttributesRequestBase & {
  uuids: string[];
}): Promise<QueryAttributesResponse['result']> {
  const shortIdChunkSize = Math.floor(MAX_CELL_DATA_TO_FETCH_IN_SINGLE_REQUEST / fields.length);
  const totalChunks = Math.ceil(uuids.length / shortIdChunkSize);
  const amortizedChunkSize = Math.ceil(uuids.length / totalChunks);

  const result = await Promise.all(
    chunk(uuids, amortizedChunkSize).map((chunk) =>
      queryAttributes({ fields, experimentIdsFilter: chunk }),
    ),
  );

  return result.flatMap(({ result: pageResult }) => pageResult);
}
