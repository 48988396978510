import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { Link, LinkProps } from '@neptune/shared/venus-ui';

type ContactSalesProps<P extends object> = LinkProps<P> & {
  subject?: string;
};

export function ContactSales<P extends object>({ subject, ...props }: ContactSalesProps<P>) {
  const href = subject
    ? `mailto:${neptuneUrls.salesEmail}?subject=${subject}`
    : `mailto:${neptuneUrls.salesEmail}`;

  return <Link {...props} href={href} />;
}

export default ContactSales;
