import React from 'react';
import { useDispatch } from 'react-redux';
import { auditTime, delay, switchMap, tap } from 'rxjs';

import { useUnderEditContext } from '@neptune/shared/common-business-logic';
import { Entity, EntityType, fetchEntity } from '@neptune/shared/entity-domain';
import { applyPauseWhenNotUnderEdit } from '@neptune/shared/venus-business-logic';

import { entityFullyUpdatedNotification } from './redux/actions';
import { getEntitiesNotificationStream, MetadataChangedNotification } from './entity-notifications';

type UseEntityUpdatesParams = {
  projectId?: string;
  entityId?: string;
  entityType: EntityType;
};

/** @deprecated todo: remove when we stop fetching entities with full (even 100k) attributes */
export function useLegacyEntityUpdates({
  projectId,
  entityId,
  entityType,
}: UseEntityUpdatesParams) {
  const dispatch = useDispatch();

  const { underEdit$ } = useUnderEditContext();

  React.useEffect(() => {
    if (!entityId || !projectId) {
      return;
    }

    const syntheticNotification: MetadataChangedNotification = {
      messageType: 'MetadataChanged',
      messageBody: { id: entityId, type: entityType },
    };

    const fetchEntityUpdate = async (notification: MetadataChangedNotification) => {
      const updatedEntity = await fetchEntity({
        id: notification.messageBody.id,
        type: entityType,
      });

      return updatedEntity;
    };

    const dispatchUpdatedEntity = (updatedEntity: Entity) => {
      dispatch(entityFullyUpdatedNotification(updatedEntity));
    };

    const subscription = getEntitiesNotificationStream(projectId, [entityId])
      .pipe(
        auditTime(1200),
        delay(300),
        applyPauseWhenNotUnderEdit(
          underEdit$,
          (value): value is MetadataChangedNotification => value?.messageType === 'MetadataChanged',
          syntheticNotification,
        ),
        switchMap(fetchEntityUpdate),
        tap(dispatchUpdatedEntity),
      )
      .subscribe();

    return () => subscription.unsubscribe();
  }, [dispatch, entityId, entityType, projectId, underEdit$]);
}
