export type XAxisValue = number | Date;
export type YAxisValue = number | [number, number, number]; // for error bars
export type DataWarning = 'same-timestamp' | 'nan-inf';
export type DataPointExtraData = {
  x: number;
  avg: number;
  min: number;
  max: number;
  stddev: number;
  runs: number;
};

export type Interpolation = boolean;

export type BucketInfo = {
  count: number;
  sum: number;
};

export type DataPoint = [
  XAxisValue,
  YAxisValue,
  DataWarning[]?,
  DataPointExtraData?,
  Interpolation?,
  BucketInfo?,
];

export type DataArray = DataPoint[][];

export function getDataPointX(point: DataPoint): XAxisValue {
  return point[0];
}

export function getDataPointMin(point: DataPoint): number {
  return Array.isArray(point[1]) ? point[1][0] : point[1];
}

export function getDataPointValue(point: DataPoint): number {
  return Array.isArray(point[1]) ? point[1][1] : point[1];
}

export function getDataPointMax(point: DataPoint): number {
  return Array.isArray(point[1]) ? point[1][2] : point[1];
}

export function getDataPointWarning(point: DataPoint): DataWarning[] | undefined {
  return point[2];
}

export function getDataPointExtraData(point: DataPoint): DataPointExtraData | undefined {
  return point[3];
}

export function getDataPointInterpolationFlag(point: DataPoint): Interpolation | undefined {
  return point[4];
}

export function getDataPointBucket(point: DataPoint): BucketInfo | undefined {
  return point[5];
}
