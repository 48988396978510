import React from 'react';

import { isMacOperatingSystem } from '@neptune/shared/common-util';
import { NameSearchMode } from '@neptune/shared/search-domain';
import { bemBlock, Button, GlyphName, Icon, KeyShortcut, Text } from '@neptune/shared/venus-ui';

import { RegExpInstructionsTooltip } from './RegExpInstructionsTooltip';

import './SearchModeSwitcher.less';

type SearchModeSwitcherProps = {
  onClick: () => void;
  value: NameSearchMode;
  regexGlyph?: GlyphName;
};

const commandKeyUnicode = '\u2318';

const block = bemBlock('search-mode-switcher');

export const SearchModeSwitcher: React.FC<SearchModeSwitcherProps> = ({
  regexGlyph = 'regexp',
  onClick,
  value,
}) => {
  return (
    <RegExpInstructionsTooltip
      placement="top-center"
      shortcutSlot={
        <KeyShortcut theme="dark">
          <Text size="xs" fontWeight="normal">
            {isMacOperatingSystem ? commandKeyUnicode : 'CTRL'}+/
          </Text>
        </KeyShortcut>
      }
    >
      <Button
        className={block({
          modifiers: {
            active: value === NameSearchMode.REGEX,
          },
        })}
        variant="transparent"
        onClick={onClick}
        data-role="search-mode-switcher-button"
        square
        data-mode={value}
      >
        <Icon glyph={regexGlyph} />
      </Button>
    </RegExpInstructionsTooltip>
  );
};
