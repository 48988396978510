import { identity } from 'lodash';

import { getRunIdentificationPair } from '@neptune/shared/entity-domain';
import { LeaderboardEntry } from '@neptune/shared/leaderboard-domain';

export function entriesToIdsAndNames(
  entries: LeaderboardEntry[],
  toArray?: undefined,
): { runIds: Set<string>; experimentNames: Set<string> };
export function entriesToIdsAndNames(
  entries: LeaderboardEntry[],
  toArray: (values: Set<string>) => string[],
): { runIds: string[]; experimentNames: string[] };

export function entriesToIdsAndNames(
  entries: LeaderboardEntry[],
  toArray: (values: Set<string>) => string[] = identity,
): {
  runIds: string[] | Set<string>;
  experimentNames: string[] | Set<string>;
} {
  const runIds = new Set<string>();
  const experimentNames = new Set<string>();

  entries.forEach((run) => {
    const runIdentificationPair = getRunIdentificationPair(run);

    if (runIdentificationPair) {
      if (runIdentificationPair.experimentName) {
        experimentNames.add(runIdentificationPair.experimentName);
      } else {
        runIds.add(runIdentificationPair.runId);
      }
    }
  });

  return { runIds: toArray(runIds), experimentNames: toArray(experimentNames) };
}
