import { DashboardConfigDTOShowMetricByEnum } from 'generated/leaderboard-client';

import { ChartConfigurationSettings } from '@neptune/charts-domain';

export const MetricDistinction = DashboardConfigDTOShowMetricByEnum;
export type MetricDistinction = DashboardConfigDTOShowMetricByEnum;

type ChartGlobalConfigFieldNames =
  | 'yAxisScale'
  | 'xAxisScale'
  | 'xAxisMetric'
  | 'xAxisMode'
  | 'metricsStepsRange'
  | 'smoothing'
  | 'xAxisRange'
  | 'previewPoints';

export type ChartGlobalConfig = Partial<
  Pick<ChartConfigurationSettings, ChartGlobalConfigFieldNames>
> & {
  showMetricBy?: MetricDistinction;
};
