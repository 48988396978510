import React from 'react';
import { uniq } from 'lodash';

import { SearchQuery, SearchQueryModelConverter } from '@neptune/search-query-domain';
import { useAsyncValue } from '@neptune/shared/common-util';

import { Attribute, isStringAttribute, KnownAttributes } from 'domain/experiment/attribute';

import { Leaderboard } from './search/leaderboard';
import { searchLeaderboard, SearchLeaderboardRequest } from './search/search-leaderboard';

const isSysNameAttribute = ({ attributeName }: Attribute) => attributeName === KnownAttributes.Name;

function getLeaderboardRunNames({ entries }: Leaderboard) {
  return uniq(
    entries
      .map(({ attributes }) => {
        const sysNameAttribute = attributes.find(isSysNameAttribute);

        if (!sysNameAttribute || !isStringAttribute(sysNameAttribute)) {
          return '';
        }

        return sysNameAttribute.value;
      })
      .filter(Boolean),
  );
}

export function useRunNames({
  projectIdentifier,
  experimentsOnly,
  searchQuery,
  limit = 50,
  includeArchived = false,
}: {
  projectIdentifier: string;
  experimentsOnly: boolean;
  searchQuery: SearchQuery;
  limit?: number;
  includeArchived?: boolean;
}) {
  const nqlQueryString = React.useMemo(() => {
    const nonArchivedQuery: SearchQuery = {
      criteria: [
        {
          attribute: KnownAttributes.Archived,
          operator: '=',
          type: 'bool',
          value: includeArchived,
        },
      ],
      operator: 'and',
    };

    return SearchQueryModelConverter.convertSearchQueryToNql({
      criteria: [nonArchivedQuery, searchQuery],
      operator: 'and',
    });
  }, [includeArchived, searchQuery]);

  const leaderboardRequest: SearchLeaderboardRequest = React.useMemo(
    () => ({
      projectIdentifier,
      query: nqlQueryString,
      type: ['run'],
      sorting: {
        dir: 'descending',
        sortBy: {
          name: 'sys/creation_time',
          type: 'datetime',
          aggregationMode: 'auto',
        },
      },
      pagination: {
        offset: 0,
        limit,
      },
      attributesToFetch: ['sys/name'],
      experimentsOnly,
    }),
    [experimentsOnly, limit, nqlQueryString, projectIdentifier],
  );

  const resolver = React.useCallback(async () => {
    const leaderboard = await searchLeaderboard(leaderboardRequest);

    return getLeaderboardRunNames(leaderboard);
  }, [leaderboardRequest]);

  const { loading: isLoading, value: runNames } = useAsyncValue({
    resolver,
  });

  return { isLoading, runNames };
}
