import {
  GroupByModelConverter,
  LeaderboardEntry,
  LeaderboardQueryNameAliases,
  searchLeaderboard,
  SortOptionsModelConverter,
  TableViewParams,
} from '@neptune/shared/leaderboard-domain';

import { knownAttributesForExperimentIdentification } from 'domain/experiment/attribute';

import { entriesToIdsAndNames } from './utils';

export async function resolveAutoCompareEntries(
  projectIdentifier: string,
  tableParams: TableViewParams,
  autoComparePool: number,
  queryNameAliases?: LeaderboardQueryNameAliases,
): Promise<LeaderboardEntry[]> {
  const leaderboardResult = await searchLeaderboard({
    attributesToFetch: [...knownAttributesForExperimentIdentification],
    projectIdentifier,
    type: ['run'],
    experimentsOnly: tableParams.experimentsOnly,
    queryNameAliases,
    grouping: tableParams.groupOptions?.groupBy?.length
      ? {
          groupBy: GroupByModelConverter.fromGroupOptionsToDomain(tableParams.groupOptions),
          openedGroups: [],
        }
      : undefined,
    pagination: {
      limit: autoComparePool,
      offset: 0,
    },
    query: tableParams.query,
    sorting: SortOptionsModelConverter.fromLegacyToDomain(tableParams.sortOptions),
  });

  return leaderboardResult.entries;
}

export async function resolveAutoCompareRunIdsAndExperimentNames(
  projectIdentifier: string,
  tableParams: TableViewParams,
  autoComparePool: number,
): Promise<{ runIds: Set<string>; experimentNames: Set<string> }> {
  const entries = await resolveAutoCompareEntries(projectIdentifier, tableParams, autoComparePool);

  return entriesToIdsAndNames(entries);
}

export async function resolveAutoCompareRunIdsAndExperimentNamesArrays(
  projectIdentifier: string,
  tableParams: TableViewParams,
  autoComparePool: number,
  queryNameAliases?: LeaderboardQueryNameAliases,
): Promise<{ runIds: string[]; experimentNames: string[] }> {
  const entries = await resolveAutoCompareEntries(
    projectIdentifier,
    tableParams,
    autoComparePool,
    queryNameAliases,
  );

  return entriesToIdsAndNames(entries, Array.from);
}
