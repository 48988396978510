export function toggleArrayValue<T>(array: T[], value: T): T[] {
  const updatedArray = [...array];
  const index = updatedArray.indexOf(value);

  if (index === -1) {
    updatedArray.push(value);
  } else {
    updatedArray.splice(index, 1);
  }

  return updatedArray;
}

/* The new value is added to the front, meanwhile, if the length of the array is greater than maxLength, values from the
 *  back of the array will be dropped */
export function mergeValueIntoArray<T>({
  array,
  value,
  maxLength,
  compareFn = (a: T, b: T) => a === b,
}: {
  array: T[];
  value: T;
  maxLength?: number;
  compareFn?: (a: T, b: T) => boolean;
}): T[] {
  // if maxLength is not provided, or it's not positive, we allow the array to grow indefinitely
  const parsedMaxLength = maxLength && maxLength > 0 ? maxLength : array.length + 1;
  const clippedArray = array.length > parsedMaxLength ? array.slice(0, parsedMaxLength) : array;
  const valueIndex = clippedArray.findIndex((item) => compareFn(item, value));

  if (valueIndex !== -1) {
    // move existing value to the front of the array
    return [
      value,
      ...clippedArray.slice(0, valueIndex),
      ...clippedArray.slice(valueIndex + 1, parsedMaxLength),
    ];
  }

  return [value, ...clippedArray.slice(0, parsedMaxLength - 1)];
}

function stringToSeed(str: string): number {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash * 31 + char) & 0xffffffff; // Ensure 32-bit integer range
  }

  return hash;
}

function seededRandom(seedString: string): () => number {
  let seed = stringToSeed(seedString);
  const m = 0x80000000; // 2^31
  const a = 1103515245; // Constants from glibc random
  const c = 12345;

  seed = (seed ^ a) & (m - 1); // Adjust the seed

  return () => {
    seed = (a * seed + c) & (m - 1);
    return seed / m; // Normalize to [0, 1)
  };
}

export function shuffleWithHash<T>(hash: string) {
  const getRandom = seededRandom(hash);

  return (array: T[]): T[] => {
    const result = [...array];

    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(getRandom() * (i + 1));

      [result[i], result[j]] = [result[j], result[i]];
    }

    return result;
  };
}
