const PREFIX_DASHES = [10, 18, 26, 34] as const;
const GAP = 3;
const DOT = 2;
const DASH = 5;

export function generateBinaryDashDotPattern(index: number): number[] {
  const pattern = [];

  const prefixDash = PREFIX_DASHES[index % PREFIX_DASHES.length];

  // Drop bits used to pick prefix and shift by 1. The binary pattern looks only
  // at the digits following the leading 1, so there must be a leading 1.
  // This way we can generate patterns with leading zeroes/dots, e.g.
  // 10010b represents ..-. and 1101b represents -.-, while 1b means no dots or dashes.
  index = Math.floor(index / PREFIX_DASHES.length) + 1;

  while (index > 1) {
    pattern.push(GAP, (index & 1) === 0 ? DOT : DASH);
    index = index >> 1;
  }

  pattern.push(GAP, prefixDash);

  return pattern.reverse();
}

const patternsCache: number[][] = [];

export function getBinaryDashDotPattern(index: number): number[] {
  if (patternsCache.length < index + 1) {
    for (let i = patternsCache.length; i <= index; ++i) {
      patternsCache.push(generateBinaryDashDotPattern(i));
    }
  }

  return patternsCache[index];
}
