import React from 'react';

import { SearchCriterion, SearchQuery } from '@neptune/search-query-domain';

export type SearchQueryContext = {
  metaQuery: SearchQuery;
  appliedFiltersCount: number;
  rawQuery: string | undefined;
  setQuery: (query: string | SearchQuery) => void;
  appendCriterion: (criterion: SearchCriterion) => void;
};

export const SearchQueryContext = React.createContext<SearchQueryContext | null>(null);
