import { CommonTokenStream, InputStream } from 'antlr4';
import { ParseTreeWalker } from 'antlr4/tree/Tree';
import { SearchRegexLexer } from 'generated/compound-search/SearchRegexLexer';
import { SearchRegexParser } from 'generated/compound-search/SearchRegexParser';

import { SearchQueryTerm, SearchQueryTermType } from '../types';

import { SearchRegexQueryParserListener } from './node-tree-listener';
import { SearchRegexQueryParserSilentErrorListener } from './silent-error-listener';
import { SearchRegexQueryParserErrorCode, SearchRegexQueryParserResult } from './types';

export const createSearchRegexLanguageParser = () => {
  function parse(input: string) {
    const parser = createParser(input);
    const listener = parseQuery(parser);
    return validateAndCreateResult(listener);
  }

  function createParser(input: string) {
    const chars = new InputStream(input);
    const lexer = new SearchRegexLexer(chars);
    const tokens = new CommonTokenStream(lexer);
    const parser = new SearchRegexParser(tokens);

    configureErrorHandling(lexer, parser);

    return parser;
  }

  function configureErrorHandling(lexer: SearchRegexLexer, parser: SearchRegexParser) {
    const errorListener = new SearchRegexQueryParserSilentErrorListener();

    lexer.removeErrorListeners();
    lexer.addErrorListener(errorListener);
    parser.removeErrorListeners();
    parser.addErrorListener(errorListener);
  }

  function parseQuery(parser: SearchRegexParser) {
    const tree = parser.query();
    const listener = new SearchRegexQueryParserListener(parser.symbolicNames);
    ParseTreeWalker.DEFAULT.walk(listener, tree);

    return listener;
  }

  function validateAndCreateResult(
    listener: SearchRegexQueryParserListener,
  ): SearchRegexQueryParserResult {
    const { status, query, errorCode } = listener;

    if (status === 'invalid') {
      return createInvalidResult(errorCode);
    }

    if (hasInvalidLastTerm(query)) {
      return createPartialResult(query);
    }

    return createValidResult(query);
  }

  function hasInvalidLastTerm(query: SearchQueryTerm[]): boolean {
    const lastTerm = query[query.length - 1];

    if (!lastTerm) {
      return false;
    }

    if (lastTerm.type === SearchQueryTermType.CRITERION && !lastTerm.value) {
      return true;
    }

    return lastTerm.type === SearchQueryTermType.OPERATOR;
  }

  function createInvalidResult(
    errorCode?: SearchRegexQueryParserErrorCode,
  ): SearchRegexQueryParserResult {
    return {
      status: 'invalid',
      query: [],
      errorCode,
    };
  }

  function createValidResult(query: SearchQueryTerm[]): SearchRegexQueryParserResult {
    return {
      status: 'valid',
      query,
    };
  }

  function createPartialResult(query: SearchQueryTerm[]): SearchRegexQueryParserResult {
    return {
      status: 'partial',
      query: query.filter((term) => !isEmptyCriterion(term)),
    };
  }

  function isEmptyCriterion(term: SearchQueryTerm): boolean {
    return term.type === SearchQueryTermType.CRITERION && !term.value;
  }

  function serialize(query: SearchQueryTerm[]): string {
    return query.reduce((acc, node) => {
      if (node.type === SearchQueryTermType.OPERATOR) {
        if (node.value === 'AND NOT') {
          return acc + ' & !';
        }

        if (node.value === 'AND') {
          return acc + ' & ';
        }

        return acc + ' | ';
      }

      const escapedValue = node.value
        .replace(/\\x20/g, '\\\\x20')
        .replace(/ /g, '\\x20')
        .replace(/^!/g, '\\!');

      return acc + escapedValue;
    }, '');
  }

  return { parse, serialize };
};

export const searchRegexLanguageParser = createSearchRegexLanguageParser();
