import React from 'react';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { BehaviorSubject, catchError, EMPTY, map, of, Subject, switchMap, tap } from 'rxjs';

import { withUnderEditFilter } from '../with-under-edit-filter';

import { handleFetch } from './handle-fetch';
import { tempo } from './tempo';

export type UsePollingParams<T, R> = {
  resolver: (query: T) => Promise<R>;
  pollingInterval?: number;
  onFetchSucceed?: (value: R, query: T) => void;
  onFetchStarted?: (query: T) => void;
  onFetchFailed?: (value: any | undefined, query: T) => void;
  onFetchAborted?: () => void;
  onAfterRetriesError?: (value: any | undefined, query: T) => void;
  onStart?: (query: T) => void;
  enabled?: boolean;
  isPaused?: boolean;
};

export type UsePollingResult<R> = {
  result: R | undefined;
  refresh: () => void;
};

export const usePolling = <T, R>(
  query: T,
  {
    resolver,
    pollingInterval = 30_000,
    onFetchStarted,
    onFetchSucceed,
    onFetchFailed,
    onFetchAborted,
    onAfterRetriesError,
    onStart,
    enabled = true,
    isPaused = false,
  }: UsePollingParams<T, R>,
  {
    backoffDelay = undefined,
    initialInterval = 250,
    maxInterval = 60_000,
    maxRetries = 100,
  }: Partial<RetryBackoffConfig> = {},
): UsePollingResult<R> => {
  const defaultBackoffDelay = React.useCallback(
    (iteration: number, initialInterval: number) => Math.pow(1.612, iteration) * initialInterval,
    [],
  );
  backoffDelay = backoffDelay || defaultBackoffDelay;
  const [result, setResult] = React.useState<R>();

  // observable that emits on every query change
  const query$ = React.useRef(new Subject<T>());

  // subject (writable and readable observable) that triggers leaderboard refresh
  const refresh$ = React.useRef(new Subject<void>());
  // callback that hides subject
  const refresh = React.useCallback(() => refresh$.current.next(), [refresh$]);

  // const isEnabled$ = React.useRef(new BehaviorSubject(enabled));
  const isPaused$ = React.useRef(new BehaviorSubject(isPaused));

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    // implementation:
    // * every query change
    // * resets tempo (which emits with changed query, with interval, with manual refresh and with application visibility change)
    // * initiates fetch (with lifecycle callbacks) dropping any earlier
    // * in case of error retry fetch with sophisticated backoff settings
    const subscription = query$.current
      .pipe(
        tap<T>((query) => onStart?.(query)),
        tempo(pollingInterval, refresh$.current, 10),
        map((query) => ({ query, timestamp: Date.now() })),
        withUnderEditFilter(isPaused$.current),
        switchMap(({ query }) =>
          of(query).pipe(
            handleFetch({
              resolver,
              onFetchStarted,
              onFetchSucceed,
              onFetchFailed,
            }),
            retryBackoff({
              backoffDelay,
              initialInterval,
              maxInterval,
              maxRetries,
              resetOnSuccess: true,
            }),
            catchError((err) => {
              onAfterRetriesError?.(err, query);
              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe(setResult);

    return () => {
      subscription.unsubscribe();
      onFetchAborted?.();
    };
  }, [
    resolver,
    pollingInterval,
    backoffDelay,
    initialInterval,
    maxInterval,
    maxRetries,
    onFetchStarted,
    onFetchSucceed,
    onFetchFailed,
    onFetchAborted,
    onAfterRetriesError,
    onStart,
    enabled,
  ]);

  React.useEffect(() => {
    isPaused$.current.next(isPaused);
  }, [isPaused]);

  React.useEffect(() => {
    if (enabled) {
      query$.current.next(query);
    }
  }, [enabled, query]);

  return {
    result,
    refresh,
  };
};
