import {
  AxisRange,
  AxisScale,
  ChartConfigurationSettings,
  JsonCompatibleAxisRange,
  PreviewPointsOption,
  removeNullsFromAxisRange,
  XAxisMode,
} from '@neptune/charts-domain';
import type {
  Widget,
  WidgetDefinitionNew,
  WidgetOptions,
  WidgetType,
} from '@neptune/shared/widgets-domain';

import { Complete } from 'common/utility-types';
import { AttributeDefinition } from 'domain/experiment/attribute';

import { WidgetDefinition } from '../core/domain/widget-definition';

export type ChartWidget = Widget & {
  type: 'chart';
  options: ChartWidgetOptions;
};

export type CustomExpression = { alias?: string; expression: string };

export type ChartWidgetOptions = {
  averaging?: boolean;
  xAxisScale?: AxisScale;
  yAxisScale?: AxisScale;
  xAxisMode?: XAxisMode;
  xAxisMetric?: AttributeDefinition;
  yAxisRange?: AxisRange;
  xAxisRange?: AxisRange;
  metricsStepsRange?: AxisRange;
  customXExpression?: string;
  customXExpressionName?: string;
  // Read previewPoints using getIncludePreview() function to apply correct default.
  previewPoints?: PreviewPointsOption;
  // @deprecated Replaced by previewPoints. Still present in stored widgets.
  includePreview?: undefined;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function compatibilityCheck(
  ChartWidgetOptions: ChartWidgetOptions,
  PartialChartConfigurationSettings: Partial<ChartConfigurationSettings>,
): {
  ChartWidgetOptions: ChartWidgetOptions;
  PartialChartConfigurationSettings: Partial<ChartConfigurationSettings>;
} {
  // Verify that ChartWidgetOptions are compatible with Partial<ChartConfigurationSettings>.
  // This is the type we want them to be, but as ChartWidgetOptions are stored in the
  // DB (with the conversion defined below, but it's weakly typed) we need to make
  // sure that changes to it are made with required care.
  // NOTE: This verification allows field to be added to ChartConfigurationSettings
  // without adding them to ChartWidgetOptions, but not the other way around.
  return {
    ChartWidgetOptions: PartialChartConfigurationSettings,
    PartialChartConfigurationSettings: ChartWidgetOptions,
  };
}

export function isChartWidget(widget: Widget): widget is ChartWidget {
  return widget.type === 'chart';
}

export type ApiChartWidgetOptions = {
  averaging?: boolean;
  xAxisScale?: AxisScale;
  yAxisMode?: AxisScale;
  xAxisMode?: XAxisMode;
  xAxisMetric?: AttributeDefinition;
  yAxisRange?: JsonCompatibleAxisRange;
  xAxisRange?: JsonCompatibleAxisRange;
  metricsStepsRange?: JsonCompatibleAxisRange;
  customXExpressionName?: string;
  customXExpression?: string;
  previewPoints?: PreviewPointsOption;
  // @deprecated Replaced by previewPoints. Kept to support old widgets saved before the change.
  includePreview?: boolean;
};

export function chartWidgetOptionsFromDomainToApi({
  averaging,
  xAxisScale,
  yAxisScale,
  xAxisMode,
  xAxisMetric,
  yAxisRange,
  metricsStepsRange,
  customXExpression,
  customXExpressionName,
  xAxisRange,
  previewPoints,
}: ChartWidgetOptions): Complete<Omit<ApiChartWidgetOptions, 'includePreview'>> {
  return {
    // List all fields explicitly to avoid saving any extra fields that might be passed to converter.
    averaging,
    xAxisScale,
    xAxisMode,
    yAxisMode: yAxisScale,
    xAxisMetric,
    yAxisRange,
    xAxisRange,
    metricsStepsRange,
    customXExpression,
    customXExpressionName,
    previewPoints,
  };
}

export function chartWidgetOptionsFromApiToDomain(
  options?: ApiChartWidgetOptions,
): Complete<Omit<ChartWidgetOptions, 'includePreview'>> {
  return {
    xAxisMetric: options?.xAxisMetric,
    xAxisMode: options?.xAxisMode,
    xAxisScale: options?.xAxisScale,
    yAxisScale: options?.yAxisMode,
    averaging: options?.averaging,
    yAxisRange: removeNullsFromAxisRange(options?.yAxisRange),
    metricsStepsRange: removeNullsFromAxisRange(options?.metricsStepsRange),
    customXExpression: options?.customXExpression,
    customXExpressionName: options?.customXExpressionName,
    xAxisRange: removeNullsFromAxisRange(options?.xAxisRange),
    previewPoints: options?.previewPoints,
  };
}

export function isChartWidgetOptions(
  options: WidgetOptions,
  widgetType: WidgetType,
): options is ChartWidgetOptions {
  const optionKeys = Object.keys(options ?? {});
  const hasChartOptions = [
    'xAxisMode',
    'xAxisScale',
    'yAxisScale',
    'yAxisRange',
    'metricsStepsRange',
    'xAxisRange',
    'includePreview',
  ].some((option) => optionKeys.includes(option));

  return widgetType === 'chart' && hasChartOptions;
}

export const chartWidgetDefinition: WidgetDefinition = {
  type: 'chart',
  fromApiToDomain: chartWidgetOptionsFromApiToDomain,
  fromDomainToApi: chartWidgetOptionsFromDomainToApi,
};

// todo: export in api layer after move to chart-alike silo
export const chartWidgetDefinitionNew: WidgetDefinitionNew = {
  widgetType: 'chart' as const,
  label: 'Chart',
  description: 'Line graph to visualise metrics against time',
  icon: 'chart-line',
  dataItem: 'chart',
  hasSettings: true,
};
