import { Observable } from 'rxjs';
import { combineLatestWith, filter, map, startWith, tap } from 'rxjs/operators';

export const withUnderEditFilter = <T>(underEdit$: Observable<boolean>) => {
  return (source: Observable<T>) => {
    let lastSeenValues: T;

    return source.pipe(
      combineLatestWith(underEdit$.pipe(startWith(false))),
      filter(([values, underEdit]) => !underEdit && lastSeenValues !== values),
      tap(([values]) => (lastSeenValues = values)),
      map(([values]) => values),
    );
  };
};
