// todo npt-13160: move this lib outside of shared
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';

import { getRunsArchivingEnabled } from 'common/featureFlag';
import { KnownAttributes } from 'domain/experiment/attribute';

export function buildSearchLeaderboardQuery(
  entityType: string,
  entityId: string,
  isArchived = false,
) {
  const metaQuery = buildSearchLeaderboardMetaQuery(entityType, entityId, [], isArchived);

  return SearchQueryModelConverter.convertSearchQueryToNql(metaQuery);
}

export function buildSearchLeaderboardMetaQuery(
  parentType: string,
  parentId: string,
  selectedItems?: string[],
  isArchived = false,
): SearchQuery {
  const criteria: Array<SearchQuery | SearchCriterion> = [];

  if (getRunsArchivingEnabled()) {
    criteria.push({
      attribute: KnownAttributes.Archived,
      operator: '=',
      type: 'bool',
      value: isArchived,
    });
  }

  if (parentType === 'model') {
    const modelCriterion: SearchCriterion = {
      attribute: KnownAttributes.ModelId,
      operator: '=',
      type: 'string',
      value: parentId,
    };
    criteria.push(modelCriterion);
  }

  if (selectedItems && selectedItems.length > 0) {
    const itemsCriterions: SearchCriterion[] = selectedItems.map((value: string) => ({
      attribute: KnownAttributes.Id,
      operator: '=',
      type: 'string',
      value,
    }));

    const itemsQuery: SearchQuery = {
      operator: 'or',
      criteria: itemsCriterions,
    };
    criteria.push(itemsQuery);
  }

  return {
    operator: 'and',
    criteria,
  };
}
